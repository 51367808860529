import React from "react";
import WorkPageComponent from "../components/landing/WorkPageComponent";
import SleepExpertCoponent from "../components/landing/SleepExpertCoponent";
import InitialProgram from "../components/landing/InitialProgram";

const WorkPage = () => {
  return (
    <>
      <WorkPageComponent />
      <InitialProgram />
    </>
  );
};

export default WorkPage;
