import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import sleepHygiene from "../../assets/images/sleepHizen.svg";
import peaceful from "../../assets/images/peaceful-sleep-concept.svg";
import Sleep_deprivation from "../../assets/images/SLEEP-DEPRIVATION.svg";

const BlogsPage = () => {
  return (
    <Container>
      <div className="py-md-4">
        <div>
          <p className="blogPageParaOne">
            <span className="blogsPageT">T</span>he Nydra Well approach is
            developed through collaboration among sleep physicians and
            behavioral scientists, incorporating principles from both Cognitive
            Behavioral Therapy for Insomnia (CBT-i) and chronic care management
            to provide comprehensive support. Below are several clinical studies
            demonstrating the effectiveness of CBT-i and sleep hygiene, upon
            which Nydra Well's principles are founded.
          </p>
        </div>
        <div className="mt-md-5">
          <p className="blogPageSubHeading">
            Personalized sleep hygiene counseling was effective in increasing
            sleep duration
          </p>
          <Row>
            <Col className="col-md-6 col-12">
              <img src={sleepHygiene} alt="sleepHygiene" width="100%" />
            </Col>
            <Col className="col-md-6 col-12">
              <h3 className="blogPagekeyFinding">Key Findings:</h3>
              <div className="p-0 m-0">
                <ul>
                  <li className="blogPageli">
                    Participants who received personalized sleep hygiene
                    counseling were able to increase their sleep duration by an
                    average of 1.2 hours per night.
                  </li>
                  <li className="blogPageli">
                    The increase in sleep duration was a significant improvement
                    compared to participants who received general sleep
                    education
                  </li>
                  <li className="blogPageli">
                    The counseling session likely helped participants make
                    behavioral changes that promoted better sleep habits and
                    improved sleep quality.
                  </li>
                </ul>
              </div>
              <h3 className="blogPagekeyFinding">Conclusion:</h3>
              <div className="p-0 m-0">
                <ul>
                  <li className="blogPageli">
                    Improving sleep duration through personalized interventions
                    may have important implications for overall health and
                    well-being, including weight management and metabolic
                    health.
                  </li>
                  <li className="blogPageli">
                    The study concluded that personalized sleep hygiene
                    counseling was effective in increasing sleep duration in
                    young, overweight adults with habitual short sleep duration.
                  </li>
                  <li className="blogPageli">
                    This study highlights the potential benefits of personalized
                    sleep interventions in addressing inadequate sleep duration,
                    particularly among populations at risk for health issues
                    such as overweight and obesity.
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <div className="my-md-4">
            <p className="blogPageLink">
              <a
                href="https://www.uchicagomedicine.org/forefront/research-and-discoveries-articles/getting-more-sleep-reduces-caloric-intake"
                target="_blank"
                rel="noopener noreferrer"
              >
                Go to External Link
              </a>
            </p>
          </div>
        </div>
      </div>

      <div>
        <div className="my-md-5">
          <p className="blogPageSubHeading mb-md-3">
            The effects of sleep hygiene education on sleep quality and
            depression: A randomized controlled trial
          </p>
          <Row className="mt-md-5">
            <Col className="col-md-6 col-12">
              <h3 className="blogPagekeyFinding">Key Findings:</h3>
              <div className="p-0 m-0">
                <ul>
                  <li className="blogPageli">
                    The intervention group showed significant improvements in
                    sleep quality compared to the control group.
                  </li>
                  <li className="blogPageli">
                    Additionally, the intervention group demonstrated reductions
                    in symptoms of depression compared to the control group.
                  </li>
                </ul>
              </div>
              <h3 className="blogPagekeyFinding">Conclusion:</h3>
              <div className="p-0 m-0">
                <ul>
                  <li className="blogPageli">
                    The study concluded that sleep hygiene education is
                    effective in improving sleep quality and reducing symptoms
                    of depression. Providing information and guidance on sleep
                    hygiene can be a valuable intervention for individuals
                    experiencing sleep disturbances and associated mood
                    symptoms.
                  </li>
                  <li className="blogPageli">
                    This study highlights the potential benefits of sleep
                    hygiene education in promoting healthy sleep habits and
                    addressing sleep-related problems, such as insomnia and
                    depression. It underscores the importance of incorporating
                    sleep hygiene principles into clinical practice to improve
                    overall sleep quality and mental well-being.
                  </li>
                </ul>
              </div>
            </Col>
            <Col className="col-md-6 col-12">
              <img src={peaceful} alt="sleepHygiene" width="100%" />
            </Col>
          </Row>
          <div className="my-md-4">
            <p className="blogPageLink">
              <a
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5296457/
                "
                target="_blank"
                rel="noopener noreferrer"
              >
                Go to External Link
              </a>
            </p>
          </div>
        </div>
      </div>

      <div>
        <div className="my-md-5">
          <p className="blogPageSubHeading mb-md-3">
            The effects of sleep hygiene education on sleep quality in patients
            with hypertension: A randomized controlled trial
          </p>
          <Row className="mt-md-5">
            <Col className="col-md-6 col-12">
              <img src={Sleep_deprivation} alt="sleepHygiene" width="100%" />
            </Col>
            <Col className="col-md-6 col-12">
              <h3 className="blogPagekeyFinding">Key Findings:</h3>
              <div className="p-0 m-0">
                <ul>
                  <li className="blogPageli">
                    The sleep hygiene education group showed significant
                    improvements in sleep quality compared to the control group.
                  </li>
                  <li className="blogPageli">
                    Participants who received sleep hygiene education
                    demonstrated greater reductions in sleep disturbances and
                    improvements in sleep efficiency compared to those who did
                    not receive the intervention.
                  </li>
                </ul>
              </div>
              <h3 className="blogPagekeyFinding">Conclusion:</h3>
              <div className="p-0 m-0">
                <ul>
                  <li className="blogPageli">
                    This randomized controlled trial concluded that sleep
                    hygiene education is effective in improving sleep quality in
                    patients with hypertension. Providing information and
                    guidance on sleep hygiene can be a valuable intervention for
                    improving sleep disturbances and promoting better sleep
                    quality in this population.
                  </li>
                  <li className="blogPageli">
                    This study highlights the potential benefits of
                    incorporating sleep hygiene education into the management of
                    hypertension, as improving sleep quality may have positive
                    effects on blood pressure control and overall cardiovascular
                    health.
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <div className="my-md-4">
            <p className="blogPageLink">
              <a
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5296457/
                "
                target="_blank"
                rel="noopener noreferrer"
              >
                Go to External Link
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="py-md-4">
        <div>
          <p className="blogPageParaOne">
            <span className="blogsPageT">T</span>he connection between sleep and
            weight gain is intricate and multifaceted. Below, several clinical
            studies elucidate different facets of this intricate relationship.
          </p>
        </div>
        <div className="mt-md-5">
          <p className="blogPageSubHeading">
            Impact of sleep duration on energy intake and weight gain
          </p>
          <Row>
            <Col>
              <h3 className="blogPagekeyFinding text-center">Key Findings:</h3>
              <div className="p-0 m-0">
                <ul>
                  <li className="blogPageli">
                    Participants consumed significantly more calories (an
                    average increase of 22%) during the period of restricted
                    sleep compared to normal sleep.
                  </li>
                  <li className="blogPageli">
                    The increase in calorie intake was primarily due to greater
                    consumption of snacks in the late evening and early morning
                    hours.
                  </li>
                  <li className="blogPageli">
                    Despite the increased caloric intake, participants did not
                    expend more energy during the period of restricted sleep. In
                    fact, energy expenditure remained relatively stable.
                  </li>
                </ul>
              </div>
              <h3 className="blogPagekeyFinding text-center">Conclusion:</h3>
              <div className="p-0 m-0">
                <ul>
                  <li className="blogPageli">
                    The study concluded that sleep restriction led to increased
                    caloric intake, particularly from snacks consumed during
                    late-night hours.
                  </li>
                  <li className="blogPageli">
                    The findings suggest that inadequate sleep may contribute to
                    weight gain by promoting overeating, without a compensatory
                    increase in energy expenditure.
                  </li>
                  <li className="blogPageli">
                    This study provides valuable insights into the relationship
                    between sleep duration and caloric intake, highlighting the
                    potential role of sleep in regulating appetite and food
                    consumption.
                  </li>
                </ul>
              </div>
            </Col>
            {/* <Col>
              <img src={sleepHygiene} alt="sleepHygiene" />
            </Col> */}
          </Row>
          <div className="my-md-4">
            <p className="blogPageLink">
              <a
                href="https://pubmed.ncbi.nlm.nih.gov/17308390/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Go to External Link
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="mt-md-5">
        <p className="blogPageSubHeading">
          Impact of insufficient sleep on total daily energy expenditure, food
          intake, and weight gain
        </p>
        <Row>
          <Col className="p-0 m-0">
            <h3 className="blogPagekeyFinding text-center">Key Findings:</h3>
            <div className="p-0 m-0">
              <ul>
                <li className="blogPageli">
                  The study found that insufficient sleep led to a reduction in
                  total daily energy expenditure, meaning participants burned
                  fewer calories when sleep-deprived compared to when they had
                  adequate sleep.
                </li>
                <li className="blogPageli">
                  Additionally, participants consumed more food (calories) when
                  sleep-deprived compared to when they had normal sleep
                  duration.
                </li>
                <li className="blogPageli">
                  The combination of decreased energy expenditure and increased
                  food intake resulted in a positive energy balance,
                  contributing to weight gain over time.
                </li>
                <li className="blogPageli">
                  These findings suggest that insufficient sleep may disrupt the
                  balance between energy intake and expenditure, leading to
                  weight gain and potentially increasing the risk of obesity.
                </li>
              </ul>
            </div>
            <h3 className="blogPagekeyFinding text-center">Conclusion:</h3>
            <div className="p-0 m-0">
              <ul>
                <li className="blogPageli">
                  The study concluded that insufficient sleep can negatively
                  impact total daily energy expenditure, food intake, and weight
                  gain.
                </li>
                <li className="blogPageli">
                  These findings highlight the importance of adequate sleep in
                  maintaining energy balance and preventing weight gain.
                </li>
                <li className="blogPageli">
                  Addressing sleep deficiencies may be an important aspect of
                  weight management and obesity prevention strategies.
                </li>
                <li className="blogPageli">
                  This study provides valuable insights into the physiological
                  mechanisms linking sleep duration with energy metabolism and
                  weight regulation, emphasizing the importance of promoting
                  healthy sleep habits for overall health and well-being.
                </li>
              </ul>
            </div>
          </Col>
          {/* <Col>
              <img src={sleepHygiene} alt="sleepHygiene" />
            </Col> */}
        </Row>
        <div className="my-md-4">
          <p className="blogPageLink">
            <a
              href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3619301/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Go to External Link
            </a>
          </p>
        </div>
      </div>

      <div className="mt-md-5">
        <p className="blogPageSubHeading">
          Association of sleep duration with adolescents' fat and carbohydrate
          consumption
        </p>
        <Row>
          <Col className="p-0 m-0">
            <h3 className="blogPagekeyFinding text-center">Key Findings:</h3>
            <div className="p-0 m-0">
              <ul>
                <li className="blogPageli">
                  Adolescents with short sleep duration consumed significantly
                  more fat and fewer carbohydrates compared to those with
                  optimal sleep duration.
                </li>
                <li className="blogPageli">
                  Conversely, adolescents with long sleep duration consumed
                  significantly fewer fats and more carbohydrates compared to
                  those with optimal sleep duration.
                </li>
                <li className="blogPageli">
                  These associations remained significant even after adjusting
                  for potential confounding factors such as age, sex, physical
                  activity, and socioeconomic status.
                </li>
              </ul>
            </div>
            <h3 className="blogPagekeyFinding text-center">Conclusion:</h3>
            <div className="p-0 m-0">
              <ul>
                <li className="blogPageli">
                  This study demonstrates a significant association between
                  sleep duration and adolescents' fat and carbohydrate
                  consumption. Adolescents with short sleep duration tended to
                  consume more fats and fewer carbohydrates, while those with
                  long sleep duration tended to consume fewer fats and more
                  carbohydrates. These findings suggest that sleep duration may
                  influence dietary behaviors in adolescents, which could have
                  implications for their overall health and well-being.
                </li>
                <li className="blogPageli">
                  This study provides valuable insights into the relationship
                  between sleep duration and dietary habits among adolescents,
                  highlighting the importance of adequate sleep for promoting
                  healthy eating behaviors.
                </li>
              </ul>
            </div>
          </Col>
          {/* <Col>
              <img src={sleepHygiene} alt="sleepHygiene" />
            </Col> */}
        </Row>
        <div className="my-md-4">
          <p className="blogPageLink">
            <a
              href="https://pubmed.ncbi.nlm.nih.gov/20857867/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Go to External Link
            </a>
          </p>
        </div>
      </div>

      <div className="mt-md-5">
        <p className="blogPageSubHeading">
          Changes in sleep duration and visceral adipose tissue accumulation
          (belly fat/abdominal fat) in adults
        </p>
        <Row>
          <Col className="p-0 m-0">
            <h3 className="blogPagekeyFinding text-center">Key Findings:</h3>
            <div className="p-0 m-0">
              <ul>
                <li className="blogPageli">
                  Shortening of sleep duration over the 6-year period was
                  associated with an increase in VAT accumulation, independent
                  of baseline VAT and other covariates. .
                </li>
                <li className="blogPageli">
                  Participants who decreased their sleep duration by 2 hours or
                  more per day experienced a greater increase in VAT
                  accumulation compared to those with stable sleep duration.
                </li>
                <li className="blogPageli">
                  Conversely, participants who increased their sleep duration by
                  2 hours or more per day had a smaller increase in VAT
                  accumulation compared to those with stable sleep duration.
                </li>
              </ul>
            </div>
            <h3 className="blogPagekeyFinding text-center">Conclusion:</h3>
            <div className="p-0 m-0">
              <ul>
                <li className="blogPageli">
                  This study suggests that changes in sleep duration over a
                  6-year period are associated with changes in visceral adipose
                  tissue accumulation in adults. Shortening of sleep duration
                  was linked to greater VAT accumulation, while lengthening of
                  sleep duration was associated with smaller increases in VAT.
                  These findings underscore the importance of sleep duration in
                  the regulation of adipose tissue distribution and metabolic
                  health.
                </li>
              </ul>
            </div>
          </Col>
          {/* <Col>
              <img src={sleepHygiene} alt="sleepHygiene" />
            </Col> */}
        </Row>
        <div className="my-md-4">
          <p className="blogPageLink">
            <a
              href="https://pubmed.ncbi.nlm.nih.gov/24420871/#:~:text=Results%3A%20Participants%20gained%20an%20average,respectively%2C%20P%20%3C%200.05"
              target="_blank"
              rel="noopener noreferrer"
            >
              Go to External Link
            </a>
          </p>
        </div>
      </div>

      <div className="mt-md-5">
        <p className="blogPageSubHeading">
          The effects of sleep restriction on energy metabolism and appetite
        </p>
        <Row>
          <Col className="p-0 m-0">
            <h3 className="blogPagekeyFinding text-center">Key Findings:</h3>
            <div className="p-0 m-0">
              <ul>
                <li className="blogPageli">
                  Sleep restriction led to a decrease in resting metabolic rate
                  compared to normal sleep, indicating reduced energy
                  expenditure.
                </li>
                <li className="blogPageli">
                  Sleep-restricted participants had impaired glucose metabolism,
                  with higher insulin resistance compared to when they had
                  normal sleep duration.
                </li>
                <li className="blogPageli">
                  Sleep restriction resulted in decreased levels of leptin (the
                  hormone that suppresses appetite) and increased levels of
                  ghrelin (the hormone that stimulates appetite), leading to
                  increased feelings of hunger and appetite.
                </li>
                <li className="blogPageli">
                  Subjective ratings of hunger and appetite were higher during
                  the period of sleep restriction compared to normal sleep.
                </li>
              </ul>
            </div>
            <h3 className="blogPagekeyFinding text-center">Conclusion:</h3>
            <div className="p-0 m-0">
              <ul>
                <li className="blogPageli">
                  This study demonstrates that sleep restriction has adverse
                  effects on energy metabolism and appetite regulation.
                  Sleep-deprived individuals experience reduced energy
                  expenditure, impaired glucose metabolism, and dysregulated
                  appetite hormones, which may contribute to increased hunger
                  and overeating. These findings highlight the importance of
                  adequate sleep in maintaining metabolic health and regulating
                  appetite.
                </li>
                <li className="blogPageli">
                  This study provides valuable insights into the physiological
                  mechanisms underlying the relationship between sleep
                  restriction, energy metabolism, and appetite regulation,
                  emphasizing the detrimental effects of insufficient sleep on
                  metabolic health and weight regulation.
                </li>
              </ul>
            </div>
          </Col>
          {/* <Col>
              <img src={sleepHygiene} alt="sleepHygiene" />
            </Col> */}
        </Row>
        <div className="my-md-4">
          <p className="blogPageLink">
            <a
              href="https://pubmed.ncbi.nlm.nih.gov/30870662/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Go to External Link
            </a>
          </p>
        </div>
      </div>

      <div className="mt-md-5">
        <p className="blogPageSubHeading">
          Sleep curtailment in healthy young men is associated with decreased
          leptin levels, elevated ghrelin levels, and increased hunger and
          appetite
        </p>
        <Row>
          <Col className="p-0 m-0">
            <h3 className="blogPagekeyFinding text-center">Key Findings:</h3>
            <div className="p-0 m-0">
              <ul>
                <li className="blogPageli">
                  Sleep curtailment led to decreased levels of leptin (the
                  hormone that suppresses appetite) compared to normal sleep
                  duration.
                </li>
                <li className="blogPageli">
                  Sleep-deprived participants had elevated levels of ghrelin
                  (the hormone that stimulates appetite) compared to when they
                  had normal sleep duration.
                </li>
                <li className="blogPageli">
                  Subjective ratings of hunger and appetite were significantly
                  higher during the period of sleep curtailment compared to
                  normal sleep.
                </li>
              </ul>
            </div>
            <h3 className="blogPagekeyFinding text-center">Conclusion:</h3>
            <div className="p-0 m-0">
              <ul>
                <li className="blogPageli">
                  This study demonstrates that sleep curtailment in healthy
                  young men is associated with alterations in
                  appetite-regulating hormones, including decreased leptin
                  levels and elevated ghrelin levels, leading to increased
                  feelings of hunger and appetite. These findings suggest that
                  insufficient sleep may dysregulate appetite control
                  mechanisms, contributing to overeating and weight gain.
                </li>
                <li className="blogPageli">
                  This study provides important insights into the physiological
                  mechanisms underlying the association between sleep
                  curtailment and changes in appetite-regulating hormones,
                  highlighting the potential role of sleep in regulating hunger
                  and appet.
                </li>
              </ul>
            </div>
          </Col>
          {/* <Col>
              <img src={sleepHygiene} alt="sleepHygiene" />
            </Col> */}
        </Row>
        <div className="my-md-4">
          <p className="blogPageLink">
            <a
              href="https://pubmed.ncbi.nlm.nih.gov/15583226/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Go to External Link
            </a>
          </p>
        </div>
      </div>
    </Container>
  );
};

export default BlogsPage;
