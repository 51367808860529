import axios from "axios";
const BASE_URL = "https://api.nydrawell.com/api";
// const BASE_URL = "https://api.svasana.com/api";

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
  },
});
