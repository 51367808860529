import { Container } from "react-bootstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const ChronotypeBarChart = ({ chronographyData }: any) => {
  const CustomizedAxisTick = (props: any) => {
    const { x, y, payload } = props;
    // const limit = 28;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={-40}
          y={0}
          dy={16}
          textAnchor="center"
          fill="#666"
          // transform="rotate(-45)"
          className="fs-10"
        >
          {/* {payload?.value.length > limit
                ? payload.value.substring(0, limit)
                : payload.value} */}
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <div className="chart-container">
      <ResponsiveContainer height="100%" width="100%">
        <BarChart
          data={chronographyData}
          margin={{ top: 5, left: -28, right: 3, bottom: 10 }}
        >
          <CartesianGrid stroke="white" fill="#EAEAF2" />
          <XAxis dataKey="name" tick={CustomizedAxisTick} interval={0} />
          <YAxis domain={[0, 1]} tickCount={7} />
          <Tooltip />
          <Bar dataKey="value" fill="#82CDFF" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChronotypeBarChart;
