import { useState, useEffect, useContext } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/authContext1";
import axios from "../../utils/axios";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Spinner from "../../utils/Spinner";
import useAxiosPrivate from "../../utils/useAxiosPrivate";

const DoctorDashboard = () => {
  const Navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { token, isAuthenticated, userId } = useContext(AuthContext);
  const [patientsList, setPatientsList] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<any>("");
  const filterResult = patientsList.filter((e: any) =>
    e.name.toLowerCase().includes(searchValue.toLowerCase())
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [isShowLoading, setShowLoading] = useState(false);

  // const handleClose = () => setIsCreateAppointmentModakshow(false);
  // const handleShow = (row: any) => {
  //   setPatientData(row);
  //   setIsCreateAppointmentModakshow(true);
  // };

  // const CustomInput = ({ value, onClick }: any) => (
  //   <div className="d-flex align-items-center custom-container rounded-5 py-2 px-3">
  //     <input
  //       type="text"
  //       value={value}
  //       onClick={onClick}
  //       style={{ outline: "none" }}
  //       className="border-0"
  //       placeholder="YYYY-MM-DD"
  //     />
  //     <span className="ml-2">
  //       <i className="bi bi-calendar"></i>
  //     </span>
  //   </div>
  // );
  // const dayClassName = (date: any, selectedDate: any) => {
  //   return date.getDate() === selectedDate?.getDate() &&
  //     date.getMonth() === selectedDate?.getMonth() &&
  //     date.getFullYear() === selectedDate?.getFullYear()
  //     ? "selected-day"
  //     : "";
  // };
  // const isDateDisabled = (date: any) => {
  //   const formattedDate = moment(date).format("yyyy-MM-DD");
  //   return isDoctorDate.includes(formattedDate);
  // };

  // const CreatePatientAppointmentModal = () => {
  //   return (
  //     <Modal show={isCreateAppointmentModakshow} onHide={handleClose} centered>
  //       <Modal.Body className="p-md-5">
  //         <div className="text-center">
  //           <div className="text-purple pb-md-4">
  //             <h5>{patientData?.name}</h5>
  //           </div>
  //           <DatePicker
  //             dateFormat="yyyy/MM/dd"
  //             selected={selectedDate}
  //             filterDate={isDateDisabled}
  //             onChange={(date) => setSelectedDate(date)}
  //             minDate={new Date()}
  //             customInput={
  //               <CustomInput
  //                 style={{
  //                   outline: "none",
  //                   background: "#EFEBFD",
  //                 }}
  //               />
  //             }
  //             calendarClassName="custom-calendar"
  //             dayClassName={(date) => dayClassName(date, selectedDate)}
  //           />
  //         </div>
  //         <div>
  //           <div>
  //             {slots?.length === 0 ? (
  //               <p className="text-danger text-center py-md-3">
  //                 {selectedDate === null ? (
  //                   <span>Choose the date to view available slots.</span>
  //                 ) : (
  //                   <span>No Slots for the day.</span>
  //                 )}
  //               </p>
  //             ) : (
  //               <>
  //                 <div className="my-md-4">
  //                   <>
  //                     <Row>
  //                       {slots?.map((slot: any, idx: number) => {
  //                         const { status } = slot;
  //                         return (
  //                           <>
  //                             <Col
  //                               className="col-md-6 col-xl-3 col-xxl-4"
  //                               key={idx}
  //                             >
  //                               <button
  //                                 onClick={() =>
  //                                   setSelectedSlot(slot?.slot_time)
  //                                 }
  //                                 disabled={status === "Booked"}
  //                                 className={`btn w-100 rounded-0 px-md-3 px-xxl-4 py-1 my-2 fw-600 border border-1 ${
  //                                   status === "Booked"
  //                                     ? "border-danger text-muted"
  //                                     : "border-light_purple text-purple"
  //                                 } text-center  ${
  //                                   selectedSlot === slot?.slot_time
  //                                     ? "bg-purple text-white"
  //                                     : ""
  //                                 }`}
  //                                 key={idx}
  //                               >
  //                                 {slot?.slot_time}
  //                               </button>
  //                             </Col>
  //                           </>
  //                         );
  //                       })}
  //                     </Row>
  //                   </>
  //                 </div>

  //                 <div className="d-flex justify-content-center">
  //                   <button
  //                     className="btn btn-purple"
  //                     onClick={handleSubmitAppointment}
  //                   >
  //                     Submit
  //                   </button>
  //                 </div>
  //               </>
  //             )}
  //           </div>
  //         </div>
  //       </Modal.Body>
  //     </Modal>
  //   );
  // };

  // const handleSubmitAppointment = () => {
  //   // console.log("patientData.id", patientData?.id);
  //   // console.log("date", moment(selectedDate).format("yyyy-MM-DD"));
  //   // console.log("time", selectedSlot);
  //   const patientId = patientData?.id;
  //   if (isAuthenticated && patientData) {
  //     axios
  //       .post(
  //         "/submitDoctorAppointment",
  //         {
  //           patient_id: patientId,
  //           date: moment(selectedDate).format("yyyy-MM-DD"),
  //           time: selectedSlot,
  //         },
  //         {
  //           headers: {
  //             Accept: "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       )
  //       .then((res) => {
  //         console.log("slots", res);
  //         // setShowWarningMessage(true);
  //       });
  //   }
  // };
  useEffect(() => {
    setShowLoading(true);
    if (isAuthenticated) {
      axiosPrivate({
        method: "POST",
        url: "/patientlist",
        data: {},
      })
        .then((response: any) => {
          if (response.status === 200) {
            setPatientsList(response.data.data);
            setShowLoading(false);
          }
        })
        .catch((err: any) => {
          return;
          setShowLoading(false);
        });
    }
  }, [isAuthenticated, token]);

  const columns = [
    {
      name: "Sr No",
      cell: (row: any, index: any) => {
        const serialNumber = (currentPage - 1) * itemsPerPage + index + 1;
        return serialNumber;
      },
    },
    {
      name: "Name",
      selector: (row: any) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: any) => row.email,
      sortable: true,
    },
    {
      name: "Registered Date",
      selector: (row: any) => row.date,
      sortable: true,
    },
    {
      name: "Actions",
      grow: 3,
      cell: (row: any) => (
        <>
          {row.sleep_profile_data === 1 && (
            <div
              className="viewresult_css me-2 fs-12 rounded-1"
              onClick={() => Navigate(`/viewData/${row.id}`)}
              style={{ cursor: "pointer" }}
            >
              Sleep&nbsp;Profile&nbsp;Data
            </div>
          )}

          <div
            className="viewdata_css fs-12 rounded-1"
            onClick={() => Navigate(`/sleepData/${row.id}`)}
            style={{ cursor: "pointer" }}
          >
            Wearable&nbsp;Data
          </div>
          <div
            className="btn-grid border_radius fs-12 ms-2"
            onClick={() => Navigate(`/doctorsPatientList/${row.id}`)}
            style={{ cursor: "pointer" }}
          >
            Patient&nbsp;Record
          </div>
          {/* <div
            className="bg-light_purple border_radius fs-12 ms-2 p-2"
            onClick={() => handleShow(row)}
            style={{ cursor: "pointer" }}
          >
            Create Appointment
          </div> */}
        </>
      ),
    },
  ];
  const totalRows = patientsList.length;
  const handleChangePage = (newPage: any) => {
    setCurrentPage(newPage);
  };
  let itemsPerPage = 10;

  return (
    <>
      {isShowLoading && <Spinner />}
      {/* <CreatePatientAppointmentModal /> */}
      <Container fluid className="px-3 px-xl-5 d-none d-md-block">
        <div className="desktop_header_height mt-md-3">
          <div style={{ marginTop: "85px" }}>
            <div>
              <h4 className="">Patients</h4>
            </div>
            <div className="Custom_card px-md-4 py-md-3">
              <div className="d-flex align-items-center justify-content-end my-md-1 mb-md-3">
                <div className="px-md-3 py-md-2 border border-purple rounded d-flex align-items-center">
                  <input
                    type="text"
                    className="w-100 border-0"
                    placeholder="Search by name"
                    style={{ outline: "none" }}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <i className="bi bi-search" style={{ color: "#6f4bff" }}></i>
                </div>
              </div>
              <div className="shadow">
                <DataTable
                  pagination
                  paginationTotalRows={totalRows}
                  onChangePage={handleChangePage}
                  paginationRowsPerPageOptions={[10]}
                  paginationPerPage={10}
                  columns={columns}
                  data={filterResult}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Container fluid className="d-block d-md-none">
        <div className=" mt-5 pt-5 d-flex align-items-center justify-content-between">
          <div>
            <h6>Patient</h6>
          </div>
          <div>
            <div className="px-3 py-2 border border-purple rounded d-flex align-items-center">
              <input
                type="text"
                className="w-100 border-0"
                placeholder="Search by name"
                style={{ outline: "none" }}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <i className="bi bi-search" style={{ color: "#6f4bff" }}></i>
            </div>
          </div>
        </div>
        {filterResult.map((patient: any, index: string) => {
          const { id } = patient;
          return (
            <Card className="my-3 border-light_purple border">
              <div className={`px-3 py-1`}>
                <div className="d-flex align-items-center justify-content-between mt-2 p-0 m-0">
                  <p className="text-uppercase fw-medium fs-14">
                    {patient.name}
                  </p>
                  {/* <p className="mx-2">|</p> */}
                  <p className="text-uppercase fw-medium fs-12">
                    {patient.date}
                  </p>
                </div>
                <div className="m-0 p-0">
                  <div className="d-flex align-items-center">
                    <i className="bi bi-envelope"></i>
                    <p className="my-0 ms-2 text-dark"> {patient.email}</p>
                  </div>
                </div>

                <div className="d-flex justify-content-around my-2">
                  {patient.sleep_profile_data === 1 && (
                    <button
                      className="btn btn-outline-purple"
                      onClick={() => Navigate(`/viewData/${id}`)}
                    >
                      Sleep&nbsp;Profile&nbsp;Data
                    </button>
                  )}
                  <button
                    className="btn btn-outline-purple"
                    onClick={() => Navigate(`/sleepData/${id}`)}
                  >
                    Wearable&nbsp;Data
                  </button>
                </div>
                <div className="d-flex justify-content-around my-3">
                  <button
                    className="btn btn-outline-purple"
                    onClick={() => Navigate(`/doctorsPatientList/${id}`)}
                    style={{ cursor: "pointer" }}
                  >
                    Patient&nbsp;Record
                  </button>
                </div>
              </div>
            </Card>
          );
        })}
      </Container>
    </>
  );
};

export default DoctorDashboard;
