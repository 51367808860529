import { useContext, useEffect, useState, useRef } from "react";
import { Card, Container, ToastContainer } from "react-bootstrap";
import AuthContext from "../../context/authContext1";
import axios from "../../utils/axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Spinner from "../../utils/Spinner";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../utils/useAxiosPrivate";

const DoctorAppointments = () => {
  const Navigate = useNavigate();
  const toastId = useRef<any>(null);
  const axiosPrivate = useAxiosPrivate();
  const { isAuthenticated, token, userId } = useContext(AuthContext);
  const [searchDate, setSearchDate] = useState<any>(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [appointmentData, setAppointmentData] = useState<any>([]);
  const fetchStatus = (status: number) => {
    switch (status) {
      case 2:
        return { color: "danger", message: "Appointment Rejected" };
      case 3:
        return { color: "success", message: "Appointment Completed" };
      case 4:
        return { color: "warning", message: "Appointment Cancelled" };
      case 5:
        return { color: "danger", message: "Appointment Auto Cancelled" };
    }
  };

  async function fetchAppointmentData() {
    setIsLoading(true);
    await axiosPrivate({
      method: "POST",
      url: "/doctor_appointments",
      data: {
        user_id: userId,
      },
    })
      .then((response: any) => {
        setAppointmentData(response.data.data);
      })
      .catch((err: any) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }

  let setDoctorAppointment =
    moment(searchDate).format("YYYY-MM-DD") ===
    moment(new Date()).format("YYYY-MM-DD");

  async function fetchAppointmentDataByDate() {
    setIsLoading(true);
    await axiosPrivate({
      method: "POST",
      url: "/doctor_appointments_byday",
      data: {
        date: moment(searchDate).format("YYYY-MM-DD"),
      },
    })
      .then((response: any) => {
        setAppointmentData(response.data.data);
      })
      .catch((err: any) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    {
      isAuthenticated && setDoctorAppointment
        ? isAuthenticated && fetchAppointmentData()
        : fetchAppointmentDataByDate();
    }
  }, [isAuthenticated, setDoctorAppointment, searchDate]);

  const submitActions = (appointmentId: number, status: number) => {
    setIsLoading(true);
    toastId.current = toast.loading("Loading...");
    axiosPrivate({
      method: "POST",
      url: "/appointment_status",
      data: {
        user_id: userId,
        appointment_id: appointmentId,
        status: status,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          toast.update(toastId.current, {
            render: `${response.data.message}`,
            type: "success",
            isLoading: false,
          });
          setTimeout(() => {
            fetchAppointmentData();
            toast.dismiss(toastId.current);
          }, 500);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  };

  const CustomInput = ({ value, onClick }: any) => (
    <div className="d-flex align-items-center custom-container rounded-1 py-2 px-3">
      <input
        type="text"
        value={value}
        onClick={onClick}
        style={{ outline: "none" }}
        className="border-0"
      />
      <span className="ml-2">
        <i className="bi bi-calendar"></i>
      </span>
    </div>
  );
  const dayClassName = (date: any, selectedDate: any) => {
    return date.getDate() === selectedDate?.getDate() &&
      date.getMonth() === selectedDate?.getMonth() &&
      date.getFullYear() === selectedDate?.getFullYear()
      ? "selected-day"
      : "";
  };

  return (
    <>
      {isLoading && <Spinner />}
      <ToastContainer />
      <Container fluid className="px-3 px-xl-5 d-none d-md-block">
        <div className="desktop_header_height">
          <div style={{ marginTop: "90px" }}>
            <div className="mb-md-3">
              <h5>My Appointments</h5>
            </div>
            <div className="Custom_card px-md-4 py-md-3">
              <div className="d-flex justify-content-end align-items-center py-md-2">
                <div>
                  <DatePicker
                    dateFormat="MM-dd-yyyy"
                    selected={searchDate}
                    onChange={(newValue) => setSearchDate(newValue)}
                    customInput={<CustomInput />}
                    calendarClassName="custom-calendar"
                    dayClassName={(date) => dayClassName(date, searchDate)}
                  />
                </div>
              </div>
              <div>
                <table className="table myTable table-striped table-responsive">
                  <thead className="thead-dark">
                    <tr className="text-dark fw-bold shadow-lg">
                      <th className="text-center" scope="col">
                        SNO
                      </th>
                      <th scope="col">Patient Name</th>
                      <th scope="col">Date</th>
                      <th scope="col">Time</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody className="pb-3 shadow">
                    {appointmentData.length > 0 ? (
                      <>
                        {appointmentData?.map((data: any, index: string) => {
                          const { id } = data;
                          const currentStatus = fetchStatus(data.status);
                          return (
                            <tr key={index + 1}>
                              <td className="text-center align-middle">
                                {index + 1}
                              </td>
                              <td className="align-middle">{data.name}</td>
                              <td className="align-middle">{data.date}</td>
                              <td className="align-middle">{data.time}</td>
                              <td className="align-middle">
                                <div className="d-flex align-items-center">
                                  {data.status === 1 && (
                                    <>
                                      <button
                                        onClick={() =>
                                          Navigate(
                                            `/doctorappointment/${data.patient_id}`
                                          )
                                        }
                                        className="btn-grid"
                                      >
                                        <i className="bi bi-list me-2"></i>
                                        Patient Record
                                      </button>

                                      <button
                                        onClick={() =>
                                          window.open(
                                            `${data.zoom_host_url}`,
                                            "_blank"
                                          )
                                        }
                                        className="button_zoom mx-md-3"
                                      >
                                        <i className="bi bi-camera-video"></i>
                                      </button>

                                      <button
                                        onClick={() => submitActions(id, 4)}
                                        className="btn-cancel border_radius"
                                      >
                                        <i className="bi bi-calendar-x me-2"></i>
                                        <span className="fs-12">
                                          Cancel Appointment
                                        </span>
                                      </button>
                                    </>
                                  )}
                                  {data.status === 0 && (
                                    <>
                                      <button
                                        onClick={() => submitActions(id, 1)}
                                        className="button_success me-md-3"
                                      >
                                        <i className="bi bi-check-circle me-2"></i>
                                        Approve
                                      </button>

                                      <button
                                        onClick={() => submitActions(id, 2)}
                                        className="button_danger"
                                      >
                                        <i className="bi bi-x-circle me-2"></i>
                                        Reject
                                      </button>
                                    </>
                                  )}
                                  {currentStatus && (
                                    <span
                                      className={`px-2 py-1 fs-12 d-flex align-items-center text-${currentStatus.color}  border border-${currentStatus.color} cursor-pointer`}
                                    >
                                      {currentStatus.message}
                                    </span>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <tr>
                        <td
                          colSpan={5}
                          className="text-purple text-center fw-bold"
                        >
                          {!setDoctorAppointment
                            ? "No Appoinments For This Date"
                            : "No Appoinments For Next 90 days"}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Container className="d-block d-md-none py-3">
        <div className="row mt-5 pt-2 d-flex align-items-center">
          <div className="col-12 my-2">
            <h6>My Appointments</h6>
          </div>

          <div className="col-12 d-flex justify-content-center py-3">
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={searchDate}
              onChange={(newValue) => setSearchDate(newValue)}
              customInput={<CustomInput />}
              calendarClassName="custom-calendar"
              dayClassName={(date) => dayClassName(date, searchDate)}
            />
          </div>
        </div>
        <div>
          {appointmentData.length > 0 ? (
            <>
              {appointmentData.map((data: any, index: string) => {
                const { id } = data;
                // const dateAndTime = timeStampConversion(data.opd_date).split(
                //   ","
                // );
                const currentStatus = fetchStatus(data.status);
                // const date = dateAndTime[0];
                // const time = dateAndTime[1].trim();
                return (
                  <Card
                    className="my-3 border-light_purple border_radius"
                    key={index + 1}
                  >
                    <div className={`px-2 py-2`}>
                      <div className="d-flex justify-content-between align-items-center my-2">
                        <div>
                          <p className="text-uppercase my-0 fw-medium text-dark d-block fs-14">
                            {data.name}
                          </p>
                        </div>
                        <div className="d-flex ">
                          <p className="my-0 fs-12 fw-bold">{data.date}</p>

                          <p className="my-0 fs-12 fw-bold">
                            <span className="mx-2">|</span>
                            {data.time}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-around">
                        {data.status === 1 && (
                          <div className="my-3">
                            <button
                              onClick={() =>
                                Navigate(
                                  `/doctorappointment/${data.patient_id}`
                                )
                              }
                              className="btn-grid border_radius fs-12"
                            >
                              Patient Record
                            </button>

                            <button
                              onClick={() =>
                                window.open(`${data.zoom_host_url}`, "_blank")
                              }
                              className="btn btn-purple border_radius mx-1"
                            >
                              <i className="bi bi-camera-video"></i>
                            </button>

                            <button
                              onClick={() => submitActions(id, 4)}
                              className="btn-cancel border_radius"
                            >
                              <span className="fs-12">Cancel Appointment</span>
                            </button>
                          </div>
                        )}

                        {data.status === 0 && (
                          <div className="d-flex my-3">
                            <button
                              onClick={() => submitActions(id, 1)}
                              className="btn bg-success me-3 "
                            >
                              <span className="text-white fs-12">Approve</span>
                            </button>

                            <button
                              onClick={() => submitActions(id, 2)}
                              className="btn bg-danger px-3"
                            >
                              <span className="fs-12 text-white">Reject</span>
                            </button>
                          </div>
                        )}

                        <div className="my-2">
                          {currentStatus && (
                            <span
                              className={`px-2 py-2  fs-12 d-flex align-items-center text-${currentStatus.color}  border border-${currentStatus.color} cursor-pointer`}
                            >
                              {currentStatus.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </Card>
                );
              })}
            </>
          ) : (
            <Card className="py-4 px-3 text-center text-purple fw-600">
              {setDoctorAppointment && "No Appoinments For This Date"}
              {/* : "No Appoinments For This Date"} */}
            </Card>
          )}
        </div>
      </Container>
    </>
  );
};

export default DoctorAppointments;
