import { useState, useEffect, useContext, useRef } from "react";
import { Card, Col, Container, Row, Spinner as Spin } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/axios";
import AuthContext from "../../context/authContext1";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ErrorToast from "../../components/ErrorToast";
import moment from "moment";
import Spinner from "../../utils/Spinner";
import { ToastContainer, toast } from "react-toastify";
import useAxiosPrivate from "../../utils/useAxiosPrivate";

const CreateAppointmentPage = () => {
  const Navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const toastId = useRef<any>(null);
  const { isAuthenticated, token } = useContext(AuthContext);
  const [doctorsList, setDoctorsList] = useState([]);
  const [selectedDate, setSelectedDate] = useState<any>(new Date());
  const [selectedDoctorId, setSelectedDoctorId] = useState<any>();
  const [isBookAppointmentClicked, setBookAppointmentClicked] = useState(false);
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState<any>();
  const [showWarningMessage, setShowWarningMessage] = useState<boolean>(false);
  const [isSubmitButtonDisplayed, setIsSubmitButtonDisplayed] =
    useState<boolean>(false);
  const [isDoctorName, setIsDoctorName] = useState();
  const [isDoctorDate, setIsDoctorDate] = useState<any>();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState(false);
  useEffect(() => {
    const delayInMilliseconds = 3000;
    const timeoutId = setTimeout(() => {
      setShowMessage(true);
    }, delayInMilliseconds);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    setShowLoading(true);
    axiosPrivate({
      method: "GET",
      url: "/doctor_list",
    })
      .then((response) => {
        setDoctorsList(response.data.data);
        setShowLoading(false);
      })
      .catch((err) => {
        return;
        setShowLoading(false);
      });
  }, [isAuthenticated, token]);

  useEffect(() => {
    if (selectedDoctorId && isAuthenticated) {
      setShowLoading(true);
      axiosPrivate({
        method: "POST",
        url: "/doctor_slots",
        data: {
          doctor_id: selectedDoctorId,
          date: moment(selectedDate).format("YYYY-MM-DD"),
        },
      })
        .then((res) => {
          setSlots(res.data.data);
        })
        .catch((err) => {
          return;
          setShowLoading(false);
        })
        .finally(() => setShowLoading(false));
    }
  }, [selectedDate, isAuthenticated, selectedDoctorId, showWarningMessage]);

  useEffect(() => {
    if (selectedDoctorId) {
      axiosPrivate({
        method: "POST",
        url: "/doctorSlotsDates",
        data: {
          doctorId: selectedDoctorId,
        },
      })
        .then((res) => {
          if (res.data.code === "200") {
            setIsDoctorDate(res.data.data);
          }
        })
        .catch((err) => {
          return;
        });
    }
  }, [selectedDoctorId, isAuthenticated]);

  const blobToImage = (blob: string) => {
    const image =
      blob !== null
        ? blob
        : "https://ionicframework.com/docs/img/demos/avatar.svg";
    return image;
  };

  let windowWidth: any = useRef(window.innerWidth);
  let screen = 767;

  let ScreenWidth = windowWidth.current > screen;

  const handleBookAppointmentSlot = (doctor: any) => {
    setSelectedDoctorId(doctor.id);
    setIsDoctorName(doctor.name);
    setBookAppointmentClicked(true);
    if (ScreenWidth) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  const handleSubmitAppointment = () => {
    setShowLoading(true);
    toastId.current = toast.loading("Loading...");
    if (isAuthenticated && selectedDoctorId) {
      axiosPrivate({
        method: "POST",
        url: "/submit_appointment",
        data: {
          doctor_id: selectedDoctorId,
          date: moment(selectedDate).format("YYYY-MM-DD"),
          time: selectedSlot,
        },
      })
        .then((res) => {
          toast.update(toastId.current, {
            render: "Your appointment has been created successfully",
            type: "success",
            isLoading: false,
          });
          setShowWarningMessage(true);
          setIsSubmitButtonDisplayed(true);

          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          setTimeout(() => {
            toast.dismiss(toastId.current);
            Navigate("/patient_appointments");
          }, 3000);
        })
        .catch((err) => {
          return;
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  };

  const CustomInput = ({ value, onClick, style }: any) => (
    <div className="d-flex align-items-center custom-container rounded-1 py-2 px-3">
      <input
        type="text"
        value={value}
        onClick={onClick}
        // style={{ outline: "none", background: "#EFEBFD" }}
        style={style}
        className="border-0 "
      />
      <span className="ml-2">
        <i className="bi bi-calendar"></i>
      </span>
    </div>
  );

  const dayClassName = (date: any, selectedDate: any) => {
    return date.getDate() === selectedDate?.getDate() &&
      date.getMonth() === selectedDate?.getMonth() &&
      date.getFullYear() === selectedDate?.getFullYear()
      ? "selected-day"
      : "";
  };

  const isDateDisabled = (date: any) => {
    const formattedDate = moment(date).format("yyyy-MM-DD");
    return isDoctorDate?.includes(formattedDate);
  };

  return (
    <>
      {showLoading && <Spinner />}

      <Container fluid className="px-3 px-xl-5 d-none d-md-block">
        <div style={{ marginTop: "85px" }}>
          <div className="row d-flex align-items-center">
            <div className="col">
              <h5 className="ff-Sora">NEW APPOINTMENT</h5>
            </div>
            <div
              className="col d-flex justify-content-end"
              onClick={() => {
                Navigate("/patient_appointments");
              }}
              style={{ cursor: "pointer" }}
            >
              <i className="btn btn-purple bi bi-arrow-left"></i>
            </div>
          </div>
          <div className="desktop_header_height">
            <div className="py-3">
              <div className="row">
                <div className="col-md-9">
                  <Card className="p-4">
                    {doctorsList.map((doctor: any) => {
                      const { profile_image } = doctor;
                      const Profile_image = blobToImage(profile_image);
                      return (
                        <>
                          <div
                            className={`row p-4 d-flex align-items-center ${
                              selectedDoctorId === doctor.id
                                ? "bg-lightiess_purple"
                                : ""
                            }`}
                            key={doctor.id}
                          >
                            <div className="col-md-2">
                              <div>
                                <img
                                  src={Profile_image}
                                  alt="profile_image"
                                  width="108px"
                                  height="108px"
                                  className="rounded-circle"
                                />
                              </div>
                            </div>
                            <div className="col-md-10 container">
                              <div className="d-flex justify-content-between align-items-center py-md-3">
                                <div className="d-flex align-items-center justify-content-center">
                                  <h5 className="pt-md-2">{doctor.name}</h5>
                                </div>
                                <div>
                                  <button
                                    className="btn btn-outline-purple outline-0"
                                    onClick={() =>
                                      handleBookAppointmentSlot(doctor)
                                    }
                                  >
                                    <i className="bi bi-plus me-2"></i>
                                    Book&nbsp;An &nbsp;Appointment
                                  </button>
                                </div>
                              </div>

                              <div>{doctor.description}</div>
                            </div>
                          </div>
                          <hr
                            style={{
                              borderTop: "2px solid #DACFFF",
                              margin: "0",
                            }}
                          />
                        </>
                      );
                    })}
                  </Card>
                </div>
                <div className="col-md-3">
                  <Card
                    className="py-md-4 px-md-3 border-0"
                    style={{ background: "#EFEBFD" }}
                  >
                    <div className="row">
                      <div className="col">
                        {isBookAppointmentClicked ? (
                          <div>
                            <div className="text-center py-md-3">
                              <h5 className="text-purple fw-bold">
                                {isDoctorName}
                              </h5>
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                              <DatePicker
                                dateFormat="MM-dd-yyyy"
                                selected={selectedDate}
                                filterDate={isDateDisabled}
                                onChange={(date) => setSelectedDate(date)}
                                todayButton={"Today"}
                                minDate={new Date()}
                                customInput={
                                  <CustomInput
                                    style={{
                                      outline: "none",
                                      background: "#EFEBFD",
                                    }}
                                  />
                                }
                                calendarClassName="custom-calendar"
                                dayClassName={(date) =>
                                  dayClassName(date, selectedDate)
                                }
                              />
                            </div>
                            <div>
                              {slots?.length === 0 ? (
                                <p className="text-danger text-center">
                                  {showMessage && "No Slots for the day."}
                                </p>
                              ) : (
                                <>
                                  <div>
                                    <Container className="my-3">
                                      <Row>
                                        {slots?.map(
                                          (slot: any, idx: number) => {
                                            const { status } = slot;
                                            return (
                                              <>
                                                <Col
                                                  className="col-md-6 col-xl-6 col-xxl-4"
                                                  key={idx}
                                                >
                                                  <button
                                                    onClick={() =>
                                                      setSelectedSlot(
                                                        slot?.slot_time
                                                      )
                                                    }
                                                    disabled={
                                                      status === "Booked"
                                                    }
                                                    className={`btn w-100 rounded-0 px-md-3 px-xxl-3 py-1 my-2 fw-500 border border-1 ${
                                                      status === "Booked"
                                                        ? "border-danger text-muted"
                                                        : "border-light_purple text-purple"
                                                    } text-center  ${
                                                      selectedSlot ===
                                                      slot?.slot_time
                                                        ? "bg-purple text-white"
                                                        : ""
                                                    }`}
                                                    key={idx}
                                                  >
                                                    {slot?.slot_time}
                                                  </button>
                                                </Col>
                                              </>
                                            );
                                          }
                                        )}
                                      </Row>
                                    </Container>
                                  </div>
                                  {isSubmitButtonDisplayed ? (
                                    showWarningMessage && (
                                      <p className="text-center">
                                        Appointment Booked Successfully
                                      </p>
                                    )
                                  ) : (
                                    <div className="d-flex justify-content-center">
                                      <button
                                        className="btn btn-purple"
                                        onClick={handleSubmitAppointment}
                                      >
                                        {showLoading ? (
                                          <>
                                            <Spin
                                              animation="border"
                                              variant="white"
                                              size="sm"
                                              className="me-3"
                                            />{" "}
                                            Submitting...
                                          </>
                                        ) : (
                                          " Submit"
                                        )}
                                      </button>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center align-items-center">
                            <p>Please Select Doctor</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Container fluid className="px-3 d-block d-md-none">
        <div className="mt-5 pt-5 d-flex align-items-center justify-content-between">
          <div>
            <h6 className="text-purple">BOOK AN APPOINTMENT</h6>
          </div>
          <div
            onClick={() => {
              Navigate("/patient_appointments");
            }}
            style={{ cursor: "pointer" }}
          >
            <i className="btn btn-purple bi bi-arrow-left"></i>
          </div>
        </div>
        <div>
          {doctorsList.map((doctor: any) => {
            // console.log("doctor", doctor.id);
            const { profile_image } = doctor;
            const Profile_image = blobToImage(profile_image);
            return (
              <Card className="my-3 border-light border" key={doctor}>
                <div className={`px-3 py-2`}>
                  <div className="d-flex align-items-center p-0 m-0">
                    <div>
                      <img
                        src={Profile_image}
                        alt="img"
                        width="60px"
                        height="60px"
                        className="rounded-circle me-3"
                      />
                    </div>
                    <div>
                      <p className={`text-uppercase fw-medium fs-14 m-0`}>
                        {doctor.name}
                      </p>
                      {/* <div className="d-flex align-items-center">
                        <i className="bi bi-envelope"></i>
                        <p className="my-0 ms-2 text-dark">{doctor.email}</p>
                      </div> */}
                      <div>{doctor.description}</div>
                    </div>
                  </div>
                  <div className="my-2">
                    <button
                      className="btn btn-purple px-3 py-0 w-100 rounded-1"
                      onClick={() => handleBookAppointmentSlot(doctor)}
                    >
                      Book&nbsp;An &nbsp;Appointment
                    </button>
                  </div>

                  {/* slot display for mobile view */}

                  {isBookAppointmentClicked &&
                    selectedDoctorId === doctor.id && (
                      <>
                        <div className="d-flex justify-content-center my-3">
                          <DatePicker
                            dateFormat="yyyy/MM/dd"
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                            todayButton={"Today"}
                            minDate={new Date()}
                            filterDate={isDateDisabled}
                            customInput={
                              <CustomInput
                                style={{
                                  outline: "none",
                                }}
                              />
                            }
                            calendarClassName="custom-calendar"
                            dayClassName={(date) =>
                              dayClassName(date, selectedDate)
                            }
                          />
                        </div>
                        <div>
                          {slots?.length === 0 ? (
                            <h6 className="text-center text-danger">
                              No Slots for the day.
                            </h6>
                          ) : (
                            <div>
                              <Container className="my-3">
                                <Row>
                                  {slots?.map((slot: any, idx: number) => {
                                    const { status } = slot;

                                    return (
                                      <Col
                                        className="text-center"
                                        xs={6}
                                        key={idx}
                                      >
                                        <button
                                          onClick={() =>
                                            setSelectedSlot(slot?.slot_time)
                                          }
                                          disabled={status === "Booked"}
                                          className={`btn w-100 rounded-0 px-md-3 px-xxl-4 py-1 my-2 fw-600 border border-1 ${
                                            status === "Booked"
                                              ? "border-danger text-muted"
                                              : "border-light_purple text-purple"
                                          } text-center  ${
                                            selectedSlot === slot?.slot_time
                                              ? "bg-purple text-white"
                                              : ""
                                          }`}
                                          key={idx}
                                        >
                                          {slot?.slot_time}
                                        </button>
                                      </Col>
                                    );
                                  })}
                                </Row>
                                <div className="d-flex justify-content-center mt-3">
                                  <button
                                    className="btn btn-purple w-50"
                                    onClick={handleSubmitAppointment}
                                  >
                                    {showLoading ? "Submitting" : "Submit"}
                                  </button>
                                </div>
                              </Container>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                </div>
              </Card>
            );
          })}
        </div>
      </Container>
    </>
  );
};

export default CreateAppointmentPage;
