import React from "react";
import SleepYourComponent from "../components/landing/SleepYourComponent";
import SleepExpertCoponent from "../components/landing/SleepExpertCoponent";
import FrequentlyAskedQuestions from "../components/landing/FrequentlyAskedQuestions";
import InitialProgram from "../components/landing/InitialProgram";

const OurSolutionPage = () => {
  return (
    <>
      <SleepYourComponent />
      <FrequentlyAskedQuestions />
      <InitialProgram />
    </>
  );
};

export default OurSolutionPage;
