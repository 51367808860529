import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../context/authContext1";
import { Col, Container, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import ErrorToast from "../../components/ErrorToast";
import Spinner from "../../utils/Spinner";
import useAxiosPrivate from "../../utils/useAxiosPrivate";

const SleepData = () => {
  let { patientId } = useParams();
  const Navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { isAuthenticated, token } = useContext(AuthContext);
  const [sleepData, setSleepData] = useState<any>([]);
  const [patientName, setPatientName] = useState<string>("");
  const [selectedFromDate, setSelectedFromDate] = useState<any>(null);
  const [selectedToDate, setSelectedToDate] = useState<any>(new Date());
  const [searchValue, setSearchValue] = useState<any>("");
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showSleepDataModal, setShowSleepDataModal] = useState(false);
  const [isdatevalide, setIsDateValide] = useState(false);

  const [isImage, setIsImage] = useState<any>("");
  const [isMessage, setIsMessage] = useState();
  const [isStatus, setIsStatus] = useState<boolean>();

  const handleSleepDataModalClose = () => setShowSleepDataModal(false);

  function SleepGraph() {
    axiosPrivate({
      method: "POST",
      url: "/sleepLevelsGraph",
      data: {
        user_id: patientId,
        formDate: "",
        toDate: "",
      },
    })
      .then((response: any) => {
        setIsStatus(response.data.status);
        if (response.data.status) {
          setIsImage(response.data.data);
        } else {
          setIsMessage(response.data.message);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setShowLoading(false));
  }

  const SleepDataModal = () => {
    return (
      <Modal
        show={showSleepDataModal}
        onHide={handleSleepDataModalClose}
        centered
        size="lg"
        style={{ maxHeight: "100%", maxWidth: "100%" }}
      >
        <Modal.Header className="border-0 bg-purple" closeButton></Modal.Header>
        <Modal.Body>
          {isStatus && (
            <div className="d-flex justify-content-center">
              <img
                src={isImage}
                alt="Patient_data"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  };

  const filteredData = sleepData.filter((entry: any) =>
    entry.sleep_date.toLowerCase().includes(searchValue.toLowerCase())
  );

  useEffect(() => {
    setShowLoading(true);

    axiosPrivate({
      method: "POST",
      url: "/getallsleepdata",
      data: {
        from_date: "2021-01-20",
        to_date: `${moment(selectedToDate).format("YYYY-MM-DD")}`,
        user_id: patientId,
      },
    })
      .then((response: any) => {
        setShowLoading(false);
        SleepGraph();
        setSleepData(response.data.data);
        setPatientName(response.data.name);
      })
      .catch((err: any) => {
        setShowLoading(false);
        return;
      });
  }, [isAuthenticated]);

  const submitHandler = () => {
    setShowLoading(true);
    const fromDate = moment(selectedFromDate).format("YYYY-MM-DD");
    const toDate = moment(selectedToDate).format("YYYY-MM-DD");

    axiosPrivate({
      method: "POST",
      url: "/getallsleepdata",
      data: {
        from_date: `${fromDate}`,
        to_date: `${toDate}`,
        user_id: patientId,
      },
    })
      .then((response: any) => {
        setShowLoading(false);
        setSleepData(response.data.data);
        setPatientName(response.data.name);
      })
      .catch((err) => {
        setShowLoading(false);
        return;
      });

    axiosPrivate({
      method: "POST",
      url: "/sleepLevelsGraph",
      data: {
        user_id: patientId,
        formDate: `${fromDate}`,
        toDate: `${toDate}`,
      },
    })
      .then((response: any) => {
        setIsStatus(response.data.status);
        if (response.data.status) {
          setIsImage(response.data.data);
        } else {
          setIsMessage(response.data.message);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setShowLoading(false));
  };
  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        paddingLeft: "0 8px",
        justifyContent: "center",
        backgroundColor: "#6f4bff",
        width: "auto",
      },
    },
    cells: {
      style: {
        fontSize: "14px",
        paddingLeft: "8px",
        paddingRight: "8px",
        justifyContent: "center",
      },
    },
  };

  const columns = [
    {
      name: "Date",
      selector: (row: any) => row.sleep_date,
      sortable: true,
      width: "150px",
    },
    {
      name: "Start Time",
      selector: (row: any) => row.start_time,
      // sortable: true,
      width: "180px",
    },
    {
      name: "End Time",
      selector: (row: any) => row.end_time,
      // sortable: true,
      width: "180px",
    },
    {
      name: "Sleep Time (hh:mm)",
      selector: (row: any) => row.minutes_asleep,
      // sortable: true,
    },
    {
      name: "Awakening Time (hh:mm)",
      selector: (row: any) => row.minutes_awake,
      // sortable: true,
      width: "220px",
    },
    {
      name: "No of Awakenings",
      selector: (row: any) => row.no_of_awakenings,
      // sortable: true,
    },
    {
      name: "Time in Bed (hh:mm)",
      selector: (row: any) => row.time_in_bed,
      // sortable: true,
      width: "auto",
    },
    {
      name: "Light Sleep (hh:mm)",
      selector: (row: any) => row.minutes_light_sleep,
      // sortable: true,
    },
    {
      name: "Rem Sleep (hh:mm)",
      selector: (row: any) => row.minutes_rem,
      // sortable: true,
    },
    {
      name: "Deep Sleep (hh:mm)",
      selector: (row: any) => row.minutes_deep_sleep,
      // sortable: true,
    },
  ];

  const CustomInput = ({ value, onClick }: any) => (
    <div className="d-flex align-items-center custom-container rounded-1 py-2 px-3">
      <input
        type="text"
        value={value}
        onClick={onClick}
        style={{ outline: "none" }}
        className="border-0 "
      />
      <span className="ml-2">
        <i className="bi bi-calendar"></i>
      </span>
    </div>
  );
  const dayClassName = (date: any, selectedDate: any) => {
    return date.getDate() === selectedDate?.getDate() &&
      date.getMonth() === selectedDate?.getMonth() &&
      date.getFullYear() === selectedDate?.getFullYear()
      ? "selected-day"
      : "";
  };
  const handleFromDateChange = (date: any) => {
    setSelectedFromDate(date);
  };

  const handleToDateChange = (date: any) => {
    setSelectedToDate(date);

    const diffInDays = Math.ceil(
      (date - selectedFromDate) / (1000 * 60 * 60 * 24)
    );

    if (diffInDays < 30) {
      setIsDateValide(true);
      setSelectedToDate(null);
    }
    setTimeout(() => {
      setIsDateValide(false);
    }, 3000);
  };

  const handleSleepDataModal = () => {
    setShowSleepDataModal(true);
  };

  return (
    <>
      <SleepDataModal />
      {isdatevalide && (
        <ErrorToast
          content="Please select a date range of at least 30 days."
          heading="Invalide Date"
          type="danger"
        />
      )}

      {/* {isSleepPattern && (
        <ErrorToast content={isMessage} heading="Error" type="danger" />
      )} */}

      {showLoading && <Spinner />}
      <Container fluid className="px-md-3 d-none d-md-block">
        <div className="desktop_header_height mt-md-3">
          <div style={{ marginTop: "85px" }}>
            <div className="d-flex justify-content-between align-items-center my-md-3">
              <div>
                <h5>Sleep Data</h5>
              </div>

              <div>
                <div
                  className="btn btn-purple me-2"
                  onClick={() => Navigate(-1)}
                >
                  <i className="bi bi-arrow-left"></i>
                </div>
              </div>
            </div>
            <div className="Custom_card px-md-3 py-md-3">
              <div className="d-none d-md-block">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                    <h5 className="text-purple me-2 fw-bold">{patientName}</h5>
                  </div>
                </div>
                <Row className="d-flex justify-content-center mt-4 mb-3 px-md-3">
                  <div className="d-flex justify-content-between align-items-center">
                    {/* <form className="px-0"> */}
                    <div className="d-flex align-items-center">
                      <div>
                        <DatePicker
                          dateFormat="MM-dd-yyyy"
                          selected={selectedFromDate}
                          onChange={handleFromDateChange}
                          todayButton={"Today"}
                          customInput={<CustomInput />}
                          calendarClassName="custom-calendar"
                          dayClassName={(date) =>
                            dayClassName(date, selectedFromDate)
                          }
                        />
                      </div>
                      <div className="mx-md-3">
                        <DatePicker
                          dateFormat="MM-dd-yyyy"
                          selected={selectedToDate}
                          onChange={handleToDateChange}
                          todayButton={"Today"}
                          maxDate={new Date()}
                          customInput={<CustomInput />}
                          calendarClassName="custom-calendar"
                          dayClassName={(date) =>
                            dayClassName(date, selectedFromDate)
                          }
                        />
                      </div>
                      <div>
                        <button
                          className="btn btn-purple border_radius"
                          onClick={submitHandler}
                        >
                          Get Sleep Data
                        </button>
                      </div>
                      <div className="ms-2">
                        <button
                          disabled={!isStatus}
                          className="btn btn-purple border_radius"
                          // onClick={() => setShowSleepDataModal(true)}
                          onClick={handleSleepDataModal}
                        >
                          Sleep Pattern Wearable
                        </button>
                      </div>
                    </div>
                    {/* </form> */}
                    <div className="px-md-3 py-md-2 border border-purple rounded d-flex align-items-center">
                      <input
                        type="text"
                        className="w-100 border-0"
                        placeholder="MM-DD-YYYY"
                        style={{ outline: "none" }}
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                      <i
                        className="bi bi-search"
                        style={{ color: "#6f4bff" }}
                      ></i>
                    </div>
                  </div>
                </Row>
              </div>
              {/* <div className="d-block d-md-none">
              <Row>
                <Col>
                  <span className="text-purple me-2 fw-bold">
                    {patientName}
                  </span>
                  <span>Sleep Data</span>
                </Col>
              </Row>
              <form className="px-0" onSubmit={submitHandler}>
                <Row>
                  <div className="text-center">
                    <button type="submit" className="btn btn-purple rounded-1">
                      Get Sleep Data
                    </button>
                  </div>
                </Row>
              </form>
            </div> */}
              <div className="shadow">
                <DataTable
                  pagination
                  columns={columns}
                  data={filteredData}
                  customStyles={tableCustomStyles}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Container fluid className="d-block d-md-none py-5 mt-5">
        <div className="d-flex justify-content-between">
          <h6>
            <span className="text-purple me-2 fw-bold">{patientName}</span>
            <span>Sleep Data</span>
          </h6>
          <div
            className="btn btn-purple me-2 px-2 py-0"
            onClick={() => Navigate(-1)}
          >
            <i className="bi bi-arrow-left"></i>
          </div>
        </div>
        <form className="px-0" onSubmit={submitHandler}>
          <div className="d-flex justify-content-between align-items-center my-3">
            <h6 className="fw-600 ">From Date :</h6>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={selectedFromDate}
              onChange={(value) => setSelectedFromDate(value)}
              todayButton={"Today"}
              customInput={<CustomInput />}
              calendarClassName="custom-calendar"
              dayClassName={(date) => dayClassName(date, selectedFromDate)}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="fw-600 ">To Date :</h6>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={selectedToDate}
              onChange={(value) => setSelectedToDate(value)}
              todayButton={"Today"}
              maxDate={new Date()}
              customInput={<CustomInput />}
              calendarClassName="custom-calendar"
              dayClassName={(date) => dayClassName(date, selectedFromDate)}
            />
          </div>
          <div className="mt-3">
            <button
              type="submit"
              className="btn btn-purple border_radius w-100"
            >
              Get Sleep Data
            </button>
          </div>
          <div className="my-2">
            <button
              disabled={!isStatus}
              className="btn btn-purple border_radius w-100"
              // onClick={() => setShowSleepDataModal(true)}
              onClick={handleSleepDataModal}
            >
              Sleep Pattern Wearable
            </button>
          </div>
          <div className="px-3 py-2 border border-purple rounded d-flex align-items-center mt-3">
            <input
              type="text"
              className="w-100 border-0"
              placeholder="MM-DD-YYYY"
              style={{ outline: "none" }}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <i className="bi bi-search" style={{ color: "#6f4bff" }}></i>
          </div>
        </form>
        <div className="mt-3">
          <DataTable pagination columns={columns} data={filteredData} />
        </div>
      </Container>
    </>
  );
};

export default SleepData;
