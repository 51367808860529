import React, { useState, useContext, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AuthContext from "../../../context/authContext1";
import Stepper from "./Stepper";
import { useNavigate } from "react-router-dom";
import {
  CheckBoxFieldGroup,
  RadioFieldGroup,
} from "../../../utils/Fromcontrol";
import { Container } from "react-bootstrap";
import useAxiosPrivate from "../../../utils/useAxiosPrivate";
import Spinner from "../../../utils/Spinner";

interface Values {
  48: [] | null;
}

const QuestionariePart6: React.FC = () => {
  const Navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { token, isAuthenticated } = useContext(AuthContext);
  // const { setActiveStepIndex } = useContext(QuestionarieContext);/

  const [questions, setQuestions] = useState([]);
  const [isQuestionFetching, setIsQuestionFetching] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const validationSchema = Yup.object({
    48: Yup.array().required("At least one checkbox is required").nullable(),
  });
  let actualInitialValues: any = {
    48: null,
  };

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      setIsQuestionFetching(true);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      axiosPrivate({
        method: "POST",
        url: "/questionaire",
        data: { part: 6 },
      })
        .then((res) => {
          const { isCompleted, data } = res.data;
          if (isCompleted) {
            const keyValueArray = data.map((question: any) => {
              if (question?.question_type === "radio") {
                return [
                  question?.id,
                  question?.isSelectedOption,
                  question?.question_type,
                ];
              } else {
                const convertedSelectedOption = question?.isSelectedOption.map(
                  (option: number) => option.toString()
                );
                return [
                  question?.id,
                  convertedSelectedOption,
                  question?.question_type,
                ];
              }
            });

            keyValueArray.forEach((keyValuePair: any) => {
              // console.log(keyValueArray);
              const key = keyValuePair[0];
              const value = keyValuePair[1];
              const questionType = keyValuePair[2];
              if (questionType === "radio") {
                actualInitialValues[key] =
                  value === null ? null : value.toString();
              } else {
                actualInitialValues[key] = value === null ? [] : value;
              }
            });

            setInitialValues(actualInitialValues);
            setQuestions(data.filter((question: any) => question?.options));
          }
          // setQuestions(
          //   res.data.data.filter((question: any) => question?.options)
          // );
        })
        .catch((err) => {
          return;
        })
        .finally(() => {
          setIsQuestionFetching(false);
          setIsLoading(false);
        });
    }
  }, [isAuthenticated]);

  const submitHandler = (values: Values) => {
    setIsSubmitting(true);
    axiosPrivate({
      method: "POST",
      url: "/submitQuestionaire",
      data: { questions: values, part: 6 },
    })
      .then((res) => {
        if (res.status === 200) {
          Navigate("/dashboard");
          // setActiveStepIndex(1);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <>
      {isLoading && <Spinner />}
      {isSubmitting && <Spinner />}
      <Container>
        <div className="my-4 d-flex justify-content-center">
          <div className="w-75">
            <Stepper />
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div onClick={() => Navigate(-1)}>
            <i className="bi bi-arrow-left me-3 fs-5"></i>
          </div>
          <div>
            <h6 className="my-2">Questionarie </h6>
          </div>
        </div>

        <Formik
          initialValues={initialValues || actualInitialValues}
          // initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
          enableReinitialize={true}
        >
          {(formikProps) => {
            return (
              <Form>
                {!isQuestionFetching && (
                  <div>
                    {questions.map((question: any) => {
                      if (question.question_type === "radio") {
                        return (
                          <RadioFieldGroup
                            key={question.id}
                            questionId={question.question_id}
                            optionId={question.option_id}
                            question={question.name}
                            questionSequence={question.question_sequence}
                            name={question.id}
                            options={question.options}
                            values={formikProps.values}
                            actualQuestionId={question.id}
                          />
                        );
                      } else if (question.question_type === "checkbox") {
                        return (
                          <CheckBoxFieldGroup
                            key={question.id}
                            questionId={question.question_id}
                            optionId={question.option_id}
                            question={question.name}
                            questionSequence={question.question_sequence}
                            name={question.id}
                            options={question.options}
                            values={formikProps.values}
                            actualQuestionId={question.id}
                          />
                        );
                      }
                    })}

                    <div className="text-center my-3">
                      <button
                        type="submit"
                        className="btn btn-purple rounded-1 px-5"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </Container>
    </>
  );
};

export default QuestionariePart6;
