import { useContext, useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import Apple_Logo from "../../assets/images/Apple-Logo 1.png";
import fitbit_logo from "../../assets/images/fitbit.png";
import Woop_logo from "../../assets/images/whoop.png";
import Line from "../../assets/images/Line 57.png";
import Nydra_logo from "../../assets/images/Nydra_Well_logo_FINAL_2 21 (2).png";
import Sleep_profile from "../../assets/images/sleep_profile.png";
import Calendar from "../../assets/images/Calendar.png";
import Idea from "../../assets/images/idea.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../utils/axios";
import AuthContext from "../../context/authContext1";
import Popover from "react-bootstrap/Popover";
import Overlay from "react-bootstrap/Overlay";
import Spinner from "../../utils/Spinner";
import useAxiosPrivate from "../../utils/useAxiosPrivate";

const PatientDashboard = () => {
  const Navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { token, userId, isAuthenticated } = useContext(AuthContext);
  const [isQuestionarieStatus, setIsQuestionarieStatus] = useState<string>();
  const [isQuestionarieTime, setIsQuestionarieTime] = useState<string>();
  const [isState, setIsState] = useState<any>();
  const [isConnected, setIsConnected] = useState<any>();
  // const [isWearableToken, setIsWearableToken] = useState<any>();
  // console.log("isWearableToken", isWearableToken);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [showComponent, setShowComponent] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleClick = (event: any) => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleOverlayClose = () => {
    setShow(show);
  };

  function QuestionaireStatusCall() {
    setIsLoading(true);
    axiosPrivate({
      method: "POST",
      url: "/questionaireStatus",
      data: {},
    })
      .then((res: any) => {
        setIsQuestionarieStatus(res.data.status);
        setIsQuestionarieTime(res.data.data);
        setIsState(res.data.status === "Completed");
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }

  function wearableStatus() {
    setIsLoading(true);
    setShowComponent(true);
    axiosPrivate({
      method: "POST",
      url: "/wearableStatus",
      data: {},
    })
      .then((res: any) => {
        const status = res.data.status;
        if (!status) {
          setIsConnected(res.data);
        } else {
          setIsConnected(res.data);
          setShowComponent(false);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }
  useEffect(() => {
    QuestionaireStatusCall();
    wearableStatus();
  }, [isAuthenticated, isQuestionarieStatus, token]);
  // useEffect(() => {
  //   setShowComponent(true);
  //   axios
  //     .post(
  //       "/questionaireStatus",
  //       {},
  //       {
  //         headers: {
  //           Accept: "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then((res: any) => {
  //       setIsQuestionarieStatus(res.data.status);
  //       setIsQuestionarieTime(res.data.data);
  //       setIsState(res.data.status === "Completed");
  //     })
  //     .catch((err) => {
  //       return;
  //     });
  // }, [isAuthenticated, isQuestionarieStatus, token]);

  //the below part code is used for closing popover while clicking out side any where on the screen

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const CardComponent = ({
    Image,
    ButtonText,
    NavigateLink,
    Status,
    time,
  }: any) => {
    return (
      <div className="mt-md-4 mt-4 card_component_shadow mx-xxl-4 card_component">
        <div className="py-md-5 py-3 d-flex flex-column justify-content-center align-items-center">
          <div
            className="row justify-content-md-center"
            style={{ height: "2rem" }}
          >
            <div className="col d-flex flex-column justify-content-center align-items-center">
              <h6 className="text-uppercase">{Status} </h6>
              <p className="text-purple fw-bold">{time}</p>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col ">
              <div
                onClick={() => Navigate(`${NavigateLink}`)}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <div>
                  <img src={Image} alt="sleep_profile_data" />
                </div>
                <div>
                  <button
                    type="button"
                    className="btn-hover color-1 rounded text-light my-3"
                  >
                    {ButtonText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleImageClick = (imageName: string) => {
    localStorage.setItem("selectedDevice", imageName);
  };

  const isStatus = isConnected?.status;
  const fitbit = isConnected?.data?.fitbit;
  const whoop = isConnected?.data?.whoop;
  const isConnectedDevice = isConnected?.data.connectedDevice;

  return (
    <>
      {isLoading && <Spinner />}

      <Container fluid className="px-3 px-xl-5 d-none d-md-block">
        <div
          style={{ marginTop: "85px" }}
          className="d-flex align-items-center justify-content-center desktop_header_height"
        >
          <div className="px-5 py-4 py-xxl-5 shadow card_width">
            {isStatus ? (
              <Container>
                <div className="d-flex justify-content-md-end">
                  <div
                    className="col-md-4 col-xxl- col bg-light_grey d-flex justify-content-center align-items-center "
                    style={{ borderRadius: "50px" }}
                  >
                    <div className="d-flex align-items-center flex-column py-md-2">
                      <div className="row">
                        <div className="col">
                          <h6 className="text-success">Connected Device</h6>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-auto mt-md-2">
                          {isConnectedDevice === "whoop" ? (
                            <img
                              src={Woop_logo}
                              className="rounded-circle"
                              style={{
                                border: "2px solid green",
                                boxShadow: "0px 4px 8px rgba(0, 128, 0, 0.4)",
                              }}
                              alt="whoop"
                            />
                          ) : isConnectedDevice === "fitbit" ? (
                            <img
                              src={fitbit_logo}
                              className="rounded-circle"
                              style={{
                                border: "2px solid green",
                                boxShadow: "0px 4px 8px rgba(0, 128, 0, 0.4)",
                              }}
                              alt="fitbit"
                            />
                          ) : (
                            <img
                              src={Apple_Logo}
                              className="rounded-circle"
                              style={{
                                border: "2px solid green",
                                boxShadow: "0px 4px 8px rgba(0, 128, 0, 0.4)",
                              }}
                              alt="another_logo"
                            />
                          )}
                        </div>

                        {/* <div className="col-auto mt-md-2">
                            {isConnectedDevice === "whoop" ? (
                              <img
                                src={Woop_logo}
                                className="rounded-circle"
                                style={{
                                  border: "2px solid green",
                                  boxShadow: "0px 4px 8px rgba(0, 128, 0, 0.4)",
                                }}
                                alt="whoop"
                              />
                            ) : (
                              <img
                                src={fitbit_logo}
                                className="rounded-circle"
                                style={{
                                  border: "2px solid green",
                                  boxShadow: "0px 4px 8px rgba(0, 128, 0, 0.4)",
                                }}
                                alt="apple_watch"
                              />
                            )}
                          </div> */}
                        <div className="col d-flex align-items-center">
                          <div
                            className="viewdata_css fs-14 rounded-1"
                            onClick={() => Navigate(`/sleepData/${userId}`)}
                          >
                            Wearable data
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            ) : (
              <Container>
                <div className="row justify-content-md-center ps-md-5 ps-xxl-0 ms-md-4 ms-xxl-0">
                  <div
                    className="col-xxl-3 col bg-light_grey d-flex justify-content-center align-items-center "
                    style={{ borderRadius: "50px" }}
                  >
                    <div className="d-flex align-items-center flex-column py-md-2">
                      <div className="row">
                        <div className="col">
                          <h6>Connect Your Wearable</h6>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col" ref={ref}>
                          <img
                            src={Apple_Logo}
                            className="rounded"
                            alt="apple_watch"
                            style={{ cursor: "pointer" }}
                            onClick={handleClick}
                          />
                          <Overlay
                            show={show}
                            target={target}
                            placement="right"
                            container={ref}
                            containerPadding={20}
                            onHide={handleOverlayClose}
                          >
                            <Popover
                              id="popover-contained"
                              bsPrefix="My-popover"
                            >
                              <Popover.Header as="h1">
                                Steps To Connect Apple Watch
                              </Popover.Header>
                              <Popover.Body>
                                <div className="py-2 px-1">
                                  <div>
                                    <div className="fs-14 mb-1">
                                      <span className="fw-bold">Step 1</span>
                                      <span>
                                        : Enable Sleep Tracking on Health App
                                        <span className="fw-bold ms-1">
                                          (Skip this step if you already use
                                          your iPhone for sleep tracking)
                                        </span>
                                      </span>
                                    </div>
                                    <ul className="fs-14">
                                      <li>
                                        Kindly download the Nydra Well App from
                                        the App Store.
                                      </li>
                                      <li>
                                        Sign in using your Nydra Well User ID
                                        and Password.
                                      </li>
                                      <li>
                                        Grant permission to the Nydra Well App
                                        to access Sleep Data from the Health
                                        App.
                                      </li>
                                      <li>
                                        Once logged in, select "Fetch Sleep
                                        Data" to initiate the process.
                                      </li>
                                      <li>
                                        The Nydra Well App will retrieve sleep
                                        data for the past 90 days.
                                      </li>
                                    </ul>
                                  </div>
                                  <div>
                                    <div className="fs-14 mb-1">
                                      <span className="fw-bold">Step 2</span>
                                      <span>: Download Nydra Well App</span>
                                    </div>
                                    <ul className="fs-14">
                                      <li>
                                        Open the App Store on your iPhone.
                                      </li>
                                      <li>
                                        Search for "Nydra Well" in the search
                                        bar.
                                      </li>
                                      <li>
                                        Locate the Nydra Well App and tap on
                                        "Download" to install it on your iPhone.
                                      </li>
                                    </ul>
                                  </div>
                                  <div>
                                    <div className="fs-14 mb-1">
                                      <span className="fw-bold">Step 3</span>
                                      <span>: Sign in to Nydra Well App</span>
                                    </div>
                                    <ul className="fs-14">
                                      <li>
                                        Open the Nydra Well App on your iPhone.
                                      </li>
                                      <li>
                                        If you are a first-time user, enter your
                                        Nydra Well User ID and Password to sign
                                        in.
                                      </li>
                                      <li>
                                        Grant permission for Nydra Well App to
                                        access Sleep Data from the Health App.
                                      </li>
                                    </ul>
                                  </div>
                                  <div>
                                    <div className="fs-14 mb-1">
                                      <span className="fw-bold">
                                        For First-Time Users:
                                      </span>
                                    </div>
                                    <ul className="fs-14">
                                      <li>
                                        Once logged in, select "Fetch Sleep
                                        Data" to initiate the process.
                                      </li>
                                      <li>
                                        The Nydra Well App will retrieve sleep
                                        data for the past 90 days if you have
                                        been using your Apple Watch for sleep
                                        tracking.
                                      </li>
                                    </ul>
                                  </div>
                                  <div>
                                    <div className="fs-14 mb-1">
                                      <span className="fw-bold">Ongoing:</span>
                                    </div>
                                    <ul className="fs-14">
                                      <li>
                                        You will receive a notification on your
                                        phone to synchronize sleep data at 8 AM
                                        and 10 AM every day.
                                      </li>
                                      <li>
                                        Click on the notification to directly
                                        log into the Nydra Well App.
                                      </li>
                                      <li>
                                        In the app, click on "Fetch Data" to
                                        extract and load the Sleep data to the
                                        Nydra platform.
                                      </li>
                                    </ul>
                                  </div>
                                  <div>
                                    <div className="fs-14 mb-1">
                                      <span className="fw-bold">Note:</span>
                                    </div>
                                    <ul className="fs-14">
                                      <li>
                                        The Nydra Well App is designed to
                                        transport your sleep data from the
                                        Health App to the Nydra platform for
                                        your sleep coach to view your sleep
                                        patterns and perform other functions.
                                      </li>
                                      <li>
                                        Please make sure your Apple Watch has
                                        more than 50% charge before you wear it
                                        to track sleep for the night.
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </Popover.Body>
                            </Popover>
                          </Overlay>
                        </div>
                        <Link className="col" to={fitbit}>
                          <img
                            src={fitbit_logo}
                            className="rounded"
                            alt="fitbit_logo"
                            onClick={() => handleImageClick("fitbit")}
                          />
                        </Link>
                        <Link className="col" to={whoop}>
                          <img
                            src={Woop_logo}
                            className="rounded"
                            alt="Woop_logo"
                            onClick={() => handleImageClick("whoop")}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col d-flex justify-content-center align-items-center">
                    <img src={Line} alt="Line" className="w-100" />
                  </div>
                  <div className="col-xxl-2 col d-flex justify-content-center align-items-center">
                    <img src={Nydra_logo} alt="Nydra_logo" />
                  </div>
                </div>
              </Container>
            )}
            <div className="row justify-content-md-center py-xxl-5">
              <div className="col-auto">
                <CardComponent
                  Status={isQuestionarieStatus}
                  time={isQuestionarieTime}
                  Image={Sleep_profile}
                  ButtonText={
                    isState ? (
                      <p>Sleep Profile Data</p>
                    ) : (
                      <p>Build Your Sleep Profile</p>
                    )
                  }
                  NavigateLink={isState ? "/sleepprofile" : "/questionarie"}
                />
              </div>
              <div className="col-auto">
                <CardComponent
                  Image={Calendar}
                  ButtonText="Book Appointment"
                  NavigateLink="/patient_appointments"
                />
              </div>
              <div className="col-auto">
                <CardComponent
                  Image={Idea}
                  ButtonText="Resource"
                  NavigateLink="/resource"
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Container fluid className="d-block d-md-none py-5 mt-5">
        <div className="row px-3">
          {isStatus ? (
            <div className="d-flex justify-content-md-end">
              <div
                className="col py-2 bg-light_grey d-flex justify-content-center align-items-center "
                style={{ borderRadius: "50px" }}
              >
                <div
                  className="d-flex align-items-center flex-column py-md-2"
                  style={{ borderRadius: "50px" }}
                >
                  <div className="row">
                    <div className="col">
                      <h6 className="text-success">Connected Device</h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      {isConnectedDevice === "whoop" ? (
                        <img
                          src={Woop_logo}
                          className="rounded-circle"
                          style={{
                            border: "2px solid green",
                            boxShadow: "0px 4px 8px rgba(0, 128, 0, 0.4)",
                          }}
                          alt="apple_watch"
                        />
                      ) : isConnectedDevice === "fitbit" ? (
                        <img
                          src={fitbit_logo}
                          className="rounded-circle"
                          style={{
                            border: "2px solid green",
                            boxShadow: "0px 4px 8px rgba(0, 128, 0, 0.4)",
                          }}
                          alt="apple_watch"
                        />
                      ) : (
                        <img
                          src={Apple_Logo}
                          className="rounded-circle"
                          style={{
                            border: "2px solid green",
                            boxShadow: "0px 4px 8px rgba(0, 128, 0, 0.4)",
                          }}
                          alt="another_logo"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="col bg-light_grey d-flex justify-content-center align-items-center py-2"
              style={{ borderRadius: "50px" }}
            >
              <div className="d-flex align-items-center flex-column">
                <div className="row">
                  <div className="col">
                    <h6>Connect Your Wearable</h6>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <img
                      src={Apple_Logo}
                      className="rounded"
                      alt="apple_watch"
                    />
                  </div>
                  <Link className="col" to={fitbit}>
                    <img
                      src={fitbit_logo}
                      className="rounded"
                      alt="fitbit_logo"
                    />
                  </Link>
                  <Link className="col" to={whoop}>
                    <img src={Woop_logo} className="rounded" alt="Woop_logo" />
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-center ">
            <CardComponent
              Status={isQuestionarieStatus}
              time={isQuestionarieTime}
              Image={Sleep_profile}
              ButtonText={
                isState ? (
                  <p>Sleep Profile Data</p>
                ) : (
                  <p>Build Your Sleep Profile</p>
                )
              }
              NavigateLink={isState ? "/sleepprofile" : "/questionarie"}
            />
          </div>
          <div className="col-12 d-flex justify-content-center">
            <CardComponent
              Image={Calendar}
              ButtonText="Book Appointment"
              NavigateLink="/patient_appointments"
            />
          </div>
          <div className="col-12 d-flex justify-content-center">
            <CardComponent
              Image={Idea}
              ButtonText="Resource"
              NavigateLink="/resource"
            />
          </div>
        </div>
      </Container>
    </>
  );
};

export default PatientDashboard;
