import React, { useContext, useEffect, useState } from "react";
import { Card, Container, Modal } from "react-bootstrap";
import AuthContext from "../../context/authContext1";
import axios from "../../utils/axios";
import CreateDoctorModal from "../../components/admin/CreateDoctorModal";
import EditDoctorModal from "../../components/admin/EditDoctorModal";
import Spinner from "../../utils/Spinner";
import useAxiosPrivate from "../../utils/useAxiosPrivate";

const DoctorList = () => {
  const { isAuthenticated, token, userRole } = useContext(AuthContext);
  const axiosPrivate = useAxiosPrivate();
  const [doctorsList, setDoctorsList] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isOpenCreateDoctorModal, setIsOpenCreateDoctorModal] =
    useState<boolean>(false);

  const [isOpenEditDoctorModal, setIsOpenEditDoctorModal] =
    useState<boolean>(false);
  const [isApiSuccess, setIsApiSuccess] = useState<boolean>(false);
  const doctorDetailsInitialValue = {
    firstname: "",
    lastname: "",
    email: "",
    mobileno: "",
    status: 0,
    id: 0,
  };
  const [doctorDetails, setDoctorDetails] = useState(doctorDetailsInitialValue);
  const [doctorId, setDoctorId] = useState<any>();
  const [isfutureAppointmentshow, setIsFutureAppointmentShow] = useState(false);
  const [isfutureAppointmentData, setIsFutureAppointmentData] = useState<any>(
    []
  );

  const [isMessage, setIsMessage] = useState();

  const editDoctorModalHandler = (doctorInfo: any) => {
    setDoctorDetails(doctorInfo);
    setIsOpenEditDoctorModal(true);
  };

  const apiSuccessHandler = () => {
    setIsApiSuccess(!isApiSuccess);
  };

  const closeCreateDoctorHandler = () => {
    setIsOpenCreateDoctorModal(false);
  };

  const closeEditDoctorHandler = () => {
    setIsOpenEditDoctorModal(false);
  };

  async function fetchDoctorsList() {
    setIsLoading(true);
    await axios
      .get("/getDoctor", {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: { data: { data: React.SetStateAction<never[]> } }) => {
        setDoctorsList(response.data.data);
      })
      .catch((err: any) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }

  const handleCloseFutureAppointment = () => {
    setIsFutureAppointmentShow(false);
    setIsFutureAppointmentData([]);
  };

  const handleShowFutureAppointment = (id: number) => {
    setIsFutureAppointmentShow(true);
    setDoctorId(id);
  };

  useEffect(() => {
    setIsLoading(true);
    axiosPrivate({
      method: "POST",
      url: "/doctorAppointments",
      data: {
        id: doctorId,
      },
    })
      .then((res) => {
        if (res.data.status) {
          setIsFutureAppointmentData(res.data.data);
        } else {
          setIsMessage(res.data.message);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setIsLoading(false));
  }, [doctorId]);

  const FutureAppointments = () => {
    return (
      <Modal
        show={isfutureAppointmentshow}
        onHide={handleCloseFutureAppointment}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Future Appointments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isfutureAppointmentData.length > 0 ? (
            <table className="table myTable table-striped table-responsive">
              <thead>
                <th className="text-center" scope="col">
                  S.NO
                </th>
                <th scope="col">Name</th>
                <th scope="col">Date</th>
                <th scope="col">Time</th>
              </thead>

              <tbody className="pb-3 shadow">
                {isfutureAppointmentData.map((doctorData: any, idx: any) => {
                  return (
                    <tr key={idx}>
                      <td className="text-center align-middle">{idx + 1}</td>
                      <td className="align-middle">{doctorData.name}</td>
                      <td className="align-middle">{doctorData.date}</td>
                      <td className="align-middle">{doctorData.time}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <tr>
              <td colSpan={3} className="text-purple text-center fw-bold">
                {isMessage}
              </td>
            </tr>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchDoctorsList();
    }
  }, [isAuthenticated, isApiSuccess]);

  const deleteDoctorHandler = (id: number) => {
    setIsLoading(true);
    axiosPrivate({
      method: "POST",
      url: "/deleteDoctor",
      data: {
        doctor_id: id,
      },
    })
      .then((response: any) => {
        if (response.status === 200) {
          apiSuccessHandler();
        }
      })
      .catch((err: any) => {
        return;
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {isLoading && <Spinner />}
      <FutureAppointments />
      <CreateDoctorModal
        isOpenCreateDoctorModal={isOpenCreateDoctorModal}
        closeCreateDoctorHandler={closeCreateDoctorHandler}
        apiSuccessHandler={apiSuccessHandler}
      />
      <EditDoctorModal
        isOpenEditDoctorModal={isOpenEditDoctorModal}
        closeEditDoctorHandler={closeEditDoctorHandler}
        apiSuccessHandler={apiSuccessHandler}
        doctorDetails={doctorDetails}
      />
      <Container fluid className="px-3 px-xl-5 d-none d-md-block">
        <div className="desktop_header_height mt-md-4">
          <div style={{ marginTop: "85px" }} className="Custom_card px-md-5">
            <div className="d-flex justify-content-between align-items-center mt-md-4 mb-md-2">
              <h4 className="">Doctors List</h4>
              <button
                onClick={() => setIsOpenCreateDoctorModal(true)}
                className="btn btn-purple"
              >
                Create Doctor
              </button>
            </div>
            <div className="shadow">
              <table className="table myTable table-striped table-responsive">
                <thead className="thead-dark">
                  <tr className="text-dark fw-bold shadow-lg">
                    <th className="text-center" scope="col">
                      S.NO
                    </th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Status</th>
                    <th scope="col" className="d-flex justify-content-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="pb-3 shadow">
                  {doctorsList.map((doctor: any, idx: number) => {
                    const { id } = doctor;
                    return (
                      <tr key={id}>
                        <td className="text-center align-middle">{idx + 1}</td>
                        <td className="align-middle">
                          {doctor.firstname} {doctor.lastname}
                        </td>
                        <td className="align-middle">{doctor.email}</td>
                        <td className="align-middle">{doctor.mobileno}</td>
                        <td className="align-middle">
                          {doctor.status === 1 ? "Inactive" : "Active"}
                        </td>
                        <td className="d-flex justify-content-center">
                          <button
                            onClick={() => editDoctorModalHandler(doctor)}
                            className="btn btn-purple text-white px-3 me-1"
                          >
                            <i className="bi bi-pencil-square me-2"></i>
                            Edit
                          </button>
                          <button
                            onClick={() => deleteDoctorHandler(id)}
                            className="btn bg-danger text-white px-3 ms-1"
                          >
                            <i className="bi bi-trash me-2"></i>
                            Delete
                          </button>
                          <button
                            onClick={() => handleShowFutureAppointment(id)}
                            className="btn btn-outline-purple ms-3"
                          >
                            Appointments
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Container>
      <Container fluid className="d-block d-md-none">
        <div className="row mt-5 pt-5 d-flex align-items-center">
          <div className="col">
            <h6>Doctor Lists</h6>
          </div>

          <div
            className="col d-flex justify-content-end"
            onClick={() => setIsOpenCreateDoctorModal(true)}
          >
            <button className="btn btn-purple px-2 py-0 m-0 d-flex align-items-center">
              <div>
                <i className="bi bi-plus-lg"></i>
              </div>
              <div className="ms-1">
                <span className="text-white ">create</span>
              </div>
            </button>
          </div>
        </div>
        <div>
          {doctorsList.map((doctor: any, idx: number) => {
            const { id } = doctor;
            return (
              <Card className="my-3 border-light border">
                <div className={`px-3 py-1`}>
                  <div className="d-flex justify-content-between align-items-center mt-2 p-0 m-0">
                    <p className="text-uppercase fw-medium fs-14">
                      {doctor.firstname} {doctor.lastname}
                    </p>
                    <p
                      className={`fs-14 ${
                        doctor.status === 1 ? "text-danger" : "text-success"
                      }`}
                    >
                      Active
                    </p>
                  </div>

                  <div>
                    <div className="d-flex align-items-center">
                      <i className="bi bi-telephone"></i>
                      <p className="my-0 ms-2 text-dark">{doctor.mobileno}</p>
                    </div>
                  </div>

                  <div className="mt-2">
                    <div className="d-flex align-items-center">
                      <i className="bi bi-envelope"></i>
                      <p className="my-0 ms-2 text-dark">{doctor.email}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end align-items-center my-2">
                    <button
                      onClick={() => editDoctorModalHandler(doctor)}
                      className=" px-4 py-1 btn btn-purple"
                    >
                      <i className="bi bi-pencil me-1"></i> Edit
                    </button>
                    <button className=" px-4 py-1 btn bg-danger text-white ms-2">
                      <i className="bi bi-trash me-1"></i>Delete
                    </button>
                  </div>
                </div>
              </Card>
            );
          })}
        </div>
      </Container>
    </>
  );
};

export default DoctorList;
