import React from "react";

import WeightLossComponent from "../components/landing/WeightLossComponent";
import IntialProgramComponent from "../components/landing/InitalProgramComponent";
import SleepExpertCoponent from "../components/landing/SleepExpertCoponent";
import FrequentlyAskedQuestions from "../components/landing/FrequentlyAskedQuestions";
import InitialProgram from "../components/landing/InitialProgram";

const WeightLossPage = () => {
  return (
    <>
      <WeightLossComponent />
      {/* <IntialProgramComponent /> */}
      <InitialProgram />
      <FrequentlyAskedQuestions />
    </>
  );
};

export default WeightLossPage;
