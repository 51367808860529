import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import AuthContext from "../context/authContext1";
import Nydra_well_logo from "../assets/images/nydraWell.png";
import { Button } from "react-bootstrap";
import Spinner from "../utils/Spinner";

const ErrorPage = () => {
  const { token, userRole, isLogin, logout } = useContext(AuthContext);
  // const localToken = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(true);
  let isTokenExpired = token === "";
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setIsLoading(false);
    };
    if (isTokenExpired) {
      checkToken();
    } else {
      setIsLoading(false);
    }
  }, []);

  const handleDashboard = () => {
    if (isTokenExpired) {
      navigate("/login");
    } else {
      if (userRole === "superadmin") {
        navigate("/managedoctors");
      } else if (userRole === "patient") {
        navigate("/dashboard");
      } else if (userRole === "doctor") {
        navigate("/doctorappointments");
      }
    }
  };
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <div className="position-relative">
            <Card
              style={{ width: "45rem" }}
              className="position-absolute top-50 start-50 translate-middle p-md-5"
            >
              <div className="text-center">
                <img src={Nydra_well_logo} alt="Nydra_well_logo" width="50%" />
              </div>

              <Card.Body>
                <Card.Text>
                  <h3 className="text-purple text-center">
                    {isTokenExpired
                      ? "Session Has Been Expired"
                      : "Page Not Found"}
                  </h3>

                  <div className="text-center mt-md-4">
                    {isLogin === "0" ? (
                      <Button
                        variant="outline-purple"
                        onClick={() => {
                          navigate("/");
                          logout();
                        }}
                      >
                        Go to Home page
                      </Button>
                    ) : (
                      <Button
                        variant="outline-purple"
                        onClick={handleDashboard}
                      >
                        {isTokenExpired ? "Click to Login" : "Go to Dashboard"}
                      </Button>
                    )}
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default ErrorPage;
