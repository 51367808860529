import { useContext, useEffect, useRef, useState } from "react";
import {
  Accordion,
  Card,
  Col,
  Container,
  Row,
  Spinner as Spin,
} from "react-bootstrap";
import AuthContext from "../../context/authContext1";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../utils/axios";
import Spinner from "../../utils/Spinner";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ErrorToast from "../../components/ErrorToast";
import { toast, ToastContainer } from "react-toastify";
import useAxiosPrivate from "../../utils/useAxiosPrivate";

const PatientData = () => {
  const Navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const toastId = useRef<any>(null);
  const { patientId }: any = useParams();
  const { token, isAuthenticated, userId } = useContext(AuthContext);
  const [isShowLoading, setShowLoading] = useState<boolean>(false);
  const [patientRecord, setPatientRecord] = useState<any>([]);
  const [doctorNotes, setDoctorNotes] = useState<string>("");
  const [medication, setMedication] = useState<string>("");
  const [isCreateAppointmentModakshow, setIsCreateAppointmentModakshow] =
    useState(false);
  const [patientData, setPatientData] = useState<any>();
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [isDoctorDate, setIsDoctorDate] = useState<any>();
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState();
  const [isToastDisplay, setIsToastDisplay] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isAppointmentId, setIsAppointmentId] = useState<any>();
  const [isCurrentAppointment, setIsCurrentAppointment] = useState();
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isSleepData, setIsSleepData] = useState();
  // const [isStatus, setIsStatus] = useState<boolean>();
  // const [isImage, setIsImage] = useState<any>("");
  // const [isMessage, setIsMessage] = useState();

  const medicationChangeHandler = (event: any) => {
    setMedication(event.target.value);
  };

  const notesChangeHandler = (event: any) => {
    setDoctorNotes(event.target.value);
  };

  function PatientRecords() {
    setShowLoading(true);
    axiosPrivate({
      method: "POST",
      url: "/patient_records",
      data: { patient_id: patientId },
    })
      .then((res: any) => {
        if (res.status === 200) {
          setIsAppointmentId(res.data?.data.appointment_id);
          setIsCurrentAppointment(res.data?.data.current_appointment_status);
          setPatientRecord(res.data?.data);
          setMedication(res.data.data?.medication?.appointment_vitals);
          setIsSleepData(res.data.sleep_profile_data);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => {
        setShowLoading(false);
      });
  }

  useEffect(() => {
    PatientRecords();
  }, [isAuthenticated]);

  useEffect(() => {
    if (userId) {
      setShowLoading(true);
      axiosPrivate({
        method: "POST",
        url: "/doctor_slots",
        data: {
          doctor_id: userId,
          date: moment(selectedDate).format("yyyy-MM-DD"),
        },
      })
        .then((res) => {
          setSlots(res.data.data);
        })
        .catch((err) => {
          return;
        })
        .finally(() => setShowLoading(false));
    }
  }, [selectedDate, userId]);

  useEffect(() => {
    if (userId) {
      axiosPrivate({
        method: "POST",
        url: "/doctorSlotsDates",
        data: {
          doctorId: userId,
        },
      })
        .then((res) => {
          if (res.data.code === "200") {
            setIsDoctorDate(res.data.data);
          }
        })
        .catch((err) => {
          return;
        });
    }
  }, []);

  const submitHandler = (event: any) => {
    event.preventDefault();
    setShowLoading(true);

    axiosPrivate({
      method: "POST",
      url: "/store_patient_records",
      data: {
        appointment_id: isAppointmentId,
        medication: medication,
        notes: doctorNotes,
      },
    })
      .then((response) => {
        const status = response.data.code === "200";
        if (status) {
          PatientRecords();
          setIsConfirmModal(false);
          setDoctorNotes("");
          toast.success(`${response.data.message}`);
          setTimeout(() => {
            Navigate("/doctorappointments");
          }, 3000);
        } else {
          toast.error(`${response.data.message}`);
        }
      })
      .catch((err) => {
        return;
      })
      .finally(() => setShowLoading(false));
  };

  const handleClose = () => {
    setIsCreateAppointmentModakshow(false);
    setSelectedDate(null);
  };
  const handleShow = (row: any) => {
    setPatientData(row);
    setIsCreateAppointmentModakshow(true);
  };

  const isDateDisabled = (date: any) => {
    const formattedDate = moment(date).format("yyyy-MM-DD");
    return isDoctorDate?.includes(formattedDate);
  };

  const CustomInput = ({ value, onClick }: any) => (
    <div className="d-flex align-items-center custom-container rounded-1 py-2 px-3">
      <input
        type="text"
        value={value}
        onClick={onClick}
        style={{ outline: "none" }}
        className="border-0"
        placeholder="MM-DD-YYYY"
      />
      <span className="ml-2">
        <i className="bi bi-calendar"></i>
      </span>
    </div>
  );
  const dayClassName = (date: any, selectedDate: any) => {
    return date.getDate() === selectedDate?.getDate() &&
      date.getMonth() === selectedDate?.getMonth() &&
      date.getFullYear() === selectedDate?.getFullYear()
      ? "selected-day"
      : "";
  };

  const handleSubmitAppointment = () => {
    const patientId = patientRecord.patient_data?.map(
      (item: any) => item.user_id
    );
    toastId.current = toast.loading("Loading...");

    if (isAuthenticated && patientData) {
      axiosPrivate({
        method: "POST",
        url: "/submitDoctorAppointment",
        data: {
          patient_id: patientId[0],
          date: moment(selectedDate).format("yyyy-MM-DD"),
          time: selectedSlot,
        },
      }).then((res) => {
        handleClose();
        toast.update(toastId.current, {
          render: "Appointment has been created successfully",
          type: "success",
          isLoading: false,
        });
        setSelectedDate(null);
        setIsToastDisplay(true);
        setTimeout(() => {
          toast.dismiss(toastId.current);
          setIsToastDisplay(false);
          Navigate(-1);
        }, 1000);
      });
    }
  };

  const handleConfirmModal = (e: any) => {
    e.preventDefault();
    setIsConfirmModal(true);
  };

  const CreatePatientAppointmentModal = () => {
    return (
      <Modal
        show={isCreateAppointmentModakshow}
        onHide={handleClose}
        backdrop="static"
        centered
        size="lg"
        fullscreen="md-down"
      >
        <Modal.Body className="p-md-5">
          <div className="text-center">
            <div className="text-purple pb-md-4">
              <h5>{patientData?.name}</h5>
            </div>
            <DatePicker
              dateFormat="MM-dd-yyyy"
              selected={selectedDate}
              filterDate={isDateDisabled}
              onChange={(date) => setSelectedDate(date)}
              minDate={new Date()}
              customInput={
                <CustomInput
                  style={{
                    outline: "none",
                    background: "#EFEBFD",
                  }}
                />
              }
              calendarClassName="custom-calendar"
              dayClassName={(date) => dayClassName(date, selectedDate)}
            />
          </div>
          <div>
            <div>
              {slots?.length === 0 ? (
                <p className="text-danger text-center py-md-3">
                  {selectedDate === null ? (
                    <span>Choose the date to view available slots.</span>
                  ) : (
                    <span>No Slots for the day.</span>
                  )}
                </p>
              ) : (
                <>
                  <div className="my-md-4">
                    <>
                      <Row>
                        {slots?.map((slot: any, idx: number) => {
                          const { status } = slot;
                          return (
                            <>
                              <Col
                                className="col-md-6 col-xl-4 col-xxl-4"
                                key={idx}
                              >
                                <button
                                  onClick={() =>
                                    setSelectedSlot(slot?.slot_time)
                                  }
                                  disabled={status === "Booked"}
                                  className={`btn w-100 rounded-0 py-1 my-2 fw-600 border border-1 ${
                                    status === "Booked"
                                      ? "border-danger text-muted"
                                      : "border-light_purple text-purple"
                                  } text-center  ${
                                    selectedSlot === slot?.slot_time
                                      ? "bg-purple text-white"
                                      : ""
                                  }`}
                                  key={idx}
                                >
                                  {slot?.slot_time}
                                </button>
                              </Col>
                            </>
                          );
                        })}
                      </Row>
                    </>
                  </div>

                  <div className="d-flex justify-content-center my-3 my-md-0">
                    <button
                      className="btn btn-purple px-5"
                      onClick={handleSubmitAppointment}
                    >
                      Submit
                    </button>
                    <button
                      onClick={handleClose}
                      className="btn btn-outline-purple px-5 ms-3"
                    >
                      Close
                    </button>
                  </div>
                </>
              )}
              {slots?.length === 0 && (
                <div className="text-center">
                  <button
                    onClick={handleClose}
                    className="btn btn-outline-purple px-5"
                  >
                    Close
                  </button>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const ConfirmModal = () => {
    return (
      <Modal
        show={isConfirmModal}
        onHide={handleClose}
        backdrop="static"
        centered
      >
        <Modal.Body className="p-md-5">
          <h5>
            Are you sure your want to complete the appointment and close the
            patient Record
          </h5>
          <div className="d-flex align-items-center justify-content-center">
            <button
              className="btn btn-purple me-md-3 px-md-4"
              onClick={submitHandler}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-purple px-md-4"
              onClick={() => setIsConfirmModal(false)}
            >
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const handleAccordionToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {isShowLoading && <Spinner />}
      <ToastContainer />
      <CreatePatientAppointmentModal />
      <ConfirmModal />
      <Container fluid className="px-3 px-xl-5 pb-md-4 d-none d-md-block">
        <div className="desktop_header_height">
          <div
            style={{ marginTop: "85px" }}
            className="Custom_card px-md-3 py-md-3"
          >
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="mb-0">Patient Records</h4>
              </div>

              <div>
                {isSleepData === 1 && (
                  <button
                    className="btn btn-purple me-2 py-2"
                    onClick={() =>
                      Navigate(
                        `/viewData/${patientRecord.patient_data[0].user_id}`
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    Sleep&nbsp;Profile&nbsp;Data
                  </button>
                )}

                {isCurrentAppointment && (
                  <button
                    className="btn btn-purple me-2 py-2"
                    onClick={() =>
                      window.open(`${patientRecord.zoom_url}`, "_blank")
                    }
                  >
                    <i className="bi bi-camera-video-fill"></i>
                  </button>
                )}

                <button
                  onClick={() =>
                    Navigate(
                      `/notification/${patientRecord.patient_data[0].user_id}`
                    )
                  }
                  className="btn btn-purple px-4 py-2 rounded-2"
                >
                  Custom Reminders/ Notifications
                </button>
                {/* {!isCurrentAppointment && (
                  <button
                    className="btn btn-purple px-4 py-2 rounded-2 ms-md-2"
                    onClick={() => handleShow(patientRecord)}
                    style={{ cursor: "pointer" }}
                  >
                    Create Appointment
                  </button>
                )} */}
                <button
                  className="btn btn-purple px-4 py-2 rounded-2 ms-md-2"
                  onClick={() => handleShow(patientRecord)}
                  style={{ cursor: "pointer" }}
                >
                  Create Appointment
                </button>
                <button
                  className="btn btn-purple ms-2 py-2"
                  onClick={() => Navigate("/doctorappointments")}
                >
                  <i className="bi bi-arrow-left"></i>
                </button>
              </div>
            </div>
            <div className="px-md-3 my-md-3">
              {patientRecord?.patient_data?.map((data: any, index: string) => {
                return (
                  <>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Address</th>
                          <th>City</th>
                          <th>State</th>
                          <th>Email</th>
                          <th>Mobile No</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {data.firstname} {data.lastname}
                          </td>
                          <td>{data.address_line_1}</td>
                          <td>{data.city}</td>
                          <td>{data.state}</td>
                          <td>{data.email}</td>
                          <td>{data.mobileno}</td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                );
              })}
            </div>
            <div className="px-md-4 py-md-4 border rounded-3">
              <Row>
                <Col className="col-md-8">
                  <Row>
                    <Col className="col-auto">
                      <div>
                        <h6 className="fs-16">
                          Previous Appointment :{" "}
                          <span className="text-purple">
                            {
                              patientRecord?.appointment_time
                                ?.previous_appointment
                            }
                          </span>
                        </h6>
                        <h6>
                          Current Appointment :
                          <span className="text-purple">
                            {" "}
                            {
                              patientRecord?.appointment_time
                                ?.current_appointment
                            }
                          </span>
                        </h6>
                        {/* <h6>
                          Next Appointment :{" "}
                          <span>
                            {" "}
                            {patientRecord?.appointment_time?.next_appointment}
                          </span>
                        </h6> */}
                      </div>
                    </Col>
                    <Col className="col-md-3 col-xxl-0 d-flex justify-content-start ps-md-5">
                      {patientRecord?.goals?.length > 0 ? (
                        <Accordion bsPrefix="custom-accordion">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header
                              className="custom-accordion-header-patient"
                              onClick={handleAccordionToggle}
                            >
                              <div className="d-flex align-items-center">
                                <span>Goals</span>
                                {!isOpen ? (
                                  <span>
                                    <i
                                      className="bi bi-plus "
                                      style={{ marginLeft: "350px" }}
                                    ></i>
                                  </span>
                                ) : (
                                  <span>
                                    <i
                                      className="bi bi-dash "
                                      style={{ marginLeft: "350px" }}
                                    ></i>
                                  </span>
                                )}
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <ul>
                                  {patientRecord?.goals?.map(
                                    (goal: any, index: string) => {
                                      return (
                                        <li className="my-md-2" key={index}>
                                          {goal}
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      ) : (
                        <p className="mt-3 px-2">No Goals</p>
                      )}
                    </Col>
                  </Row>

                  <hr className="border_primary mb-2" />
                  <Row>
                    <div className="d-flex justify-content-center gap-x-1">
                      <button
                        className="btn btn-purple rounded-2 p-2 px-4"
                        onClick={() =>
                          Navigate(
                            `/sleepdata/${patientRecord.patient_data[0].user_id}`
                          )
                        }
                      >
                        Sleep Data
                      </button>
                      <button
                        className="btn btn-purple rounded-2 p-2 px-4 mx-4"
                        onClick={() =>
                          Navigate(
                            `/view_patient_form/${patientRecord.patient_data[0].user_id}`
                          )
                        }
                      >
                        Self Assement Data
                      </button>
                      <button
                        className="btn btn-purple rounded-2 p-2 px-4"
                        onClick={() =>
                          Navigate(
                            `/sleeppatternwearable/${patientRecord.patient_data[0].user_id}`
                          )
                        }
                      >
                        Sleep Pattern Wearable
                      </button>
                    </div>
                  </Row>
                  <hr className="border_primary mt-2" />
                  <Row>
                    <Col>
                      <h6 className="ms-4 text-primary fw-bold mt-2">
                        Conversations/Coaching
                      </h6>
                      <div className="" id="style-3">
                        <div className="p-3">
                          {patientRecord?.appointment_notes?.length > 0 ? (
                            patientRecord?.appointment_notes?.map(
                              (note: any, index: string) => {
                                return (
                                  <p
                                    key={index}
                                    className="my-0 my-md-3 mt-2 mt-md-0"
                                  >
                                    <span className="border_primary text-purple fw-600 me-2 p-1">
                                      {note?.date}
                                    </span>
                                    <span>{note?.notes}</span>
                                  </p>
                                );
                              }
                            )
                          ) : (
                            <p className="m-0 fs-14">
                              No Conversations/Coaching Yet
                            </p>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col className="col-md-4 px-md-3 py-md-2">
                  {/* <div className="text-center my-2">
                  <span className="text-primary fw-bold text-decoration-underline cursor-pointer">
                    Start Video Conference
                  </span>
                </div> */}
                  <form>
                    <textarea
                      className="w-100 border border-purple rounded-2 my-md-2"
                      placeholder="No Medication Yet"
                      value={medication ? medication : ""}
                      onChange={medicationChangeHandler}
                      rows={8}
                    ></textarea>
                    <textarea
                      className="w-100 border border-purple rounded-2"
                      placeholder="No Notes Yet"
                      value={doctorNotes}
                      onChange={notesChangeHandler}
                      rows={8}
                    ></textarea>

                    <div className="text-center my-3">
                      {isCurrentAppointment && (
                        <button
                          className="btn btn-purple text-white px-5 py-2 border border-0"
                          onClick={handleConfirmModal}
                        >
                          {/* {isShowLoading ? (
                            <>
                              <Spin
                                animation="border"
                                variant="purple"
                                size="sm"
                                className="me-3"
                              />{" "}
                              Submitting...
                            </>
                          ) : ( */}
                          Submit
                          {/* )} */}
                        </button>
                      )}
                    </div>
                  </form>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>

      {/* Mobile */}
      <Container fluid className="d-block d-md-none">
        <div className=" mt-5 pt-5 d-flex align-items-center justify-content-between">
          <div>
            <h6>Patient Records</h6>
          </div>
          <div>
            <div
              className="btn btn-purple border-0 me-2"
              onClick={() => {
                Navigate(-1);
              }}
            >
              <i className="bi bi-arrow-left"></i>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between my-2">
          <button className="btn btn-purple">
            <i className="bi bi-camera-video-fill"></i>
          </button>
          <button
            onClick={() =>
              Navigate(`/notification/${patientRecord.patient_data[0].user_id}`)
            }
            className="btn btn-purple px-4 py-2 rounded-2"
          >
            Custom Reminders/ Notifications
          </button>
        </div>
        <div
          className={`my-3 ${
            isSleepData === 1
              ? "d-flex justify-content-between"
              : "d-flex justify-content-end"
          }`}
        >
          {isSleepData === 1 && (
            <button
              className="btn btn-purple py-2"
              onClick={() =>
                Navigate(`/viewData/${patientRecord.patient_data[0].user_id}`)
              }
              style={{ cursor: "pointer" }}
            >
              Sleep&nbsp;Profile&nbsp;Data
            </button>
          )}
          <button
            className="btn btn-purple px-4 py-2 rounded-2"
            onClick={() => handleShow(patientRecord)}
            style={{ cursor: "pointer" }}
          >
            Create Appointment
          </button>
        </div>

        {/* <div className="my-2">
          <button
            className="btn btn-purple px-4 py-2 rounded-2 ms-md-2 w-100"
            onClick={() => handleShow(patientRecord)}
            style={{ cursor: "pointer" }}
          >
            Create Appointment
          </button>
        </div> */}
        <div className="pb-3">
          {patientRecord?.patient_data?.map((data: any, index: string) => {
            return (
              <>
                <Card className="p-3">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6 className="fs-10 fw-400 text-muted">Name</h6>
                      <h6 className="fs-12 fw-600">
                        {data.firstname} {data.lastname}
                      </h6>
                    </div>
                    <div>
                      <h6 className="fs-10 fw-400 text-muted">Mobile No</h6>
                      <h6 className="fs-12 fw-600">{data.mobileno}</h6>
                    </div>
                  </div>
                  <div>
                    <h6 className="fs-10 fw-400 text-muted">Email</h6>
                    <h6 className="fs-12 fw-600">{data.email}</h6>
                  </div>
                  <div className="d-flex justify-content-between align-items-center pb-2 border-bottom border-light_purple">
                    <div>
                      <h6 className="fs-10 fw-400 text-muted">Address</h6>
                      <h6 className="fs-12 fw-600">{data.address_line_1}</h6>
                    </div>
                    <div>
                      <h6 className="fs-10 fw-400 text-muted">City</h6>
                      <h6 className="fs-12 fw-600">{data.city}</h6>
                    </div>
                    <div>
                      <h6 className="fs-10 fw-400 text-muted">State</h6>
                      <h6 className="fs-12 fw-600">{data.state}</h6>
                    </div>
                  </div>
                  <div className="pt-3">
                    <h6 className="fs-14">
                      Previous Appointment :{" "}
                      <span className="text-purple">
                        {patientRecord?.appointment_time?.previous_appointment}
                      </span>
                    </h6>
                    <h6 className="fs-14">
                      Current Appointment :
                      <span className="text-purple">
                        {" "}
                        {patientRecord?.appointment_time?.current_appointment}
                      </span>
                    </h6>
                    {/* <div className="d-flex justify-content-between">
                      <h6 className="fs-10 fw-400 text-muted">
                        Next Appointment
                      </h6>
                      <h6 className="fs-12 fw-600">2023-04-11 12:20:00</h6>
                    </div> */}
                  </div>
                </Card>
                <Card className="mt-2 p-3">
                  <h6 className="text-primary fw-bold fs-14">Goals</h6>
                  {patientRecord?.goals?.length > 0 ? (
                    <div>
                      <ul>
                        {patientRecord?.goals?.map(
                          (goal: any, index: string) => {
                            return (
                              <li key={index} className="fs-12 m-0 p-0">
                                {goal}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  ) : (
                    <p>No Goals</p>
                  )}
                </Card>
                <div className="m-0 mt-2 p-0">
                  <button
                    className="btn btn-purple w-100"
                    onClick={() =>
                      Navigate(
                        `/viewdata/${patientRecord.patient_data[0].user_id}`
                      )
                    }
                  >
                    Sleep&nbsp;Pattern&nbsp;Wearable
                  </button>
                </div>
                <div className="m-0 mt-2 p-0">
                  <button
                    className="btn btn-purple w-100"
                    onClick={() =>
                      Navigate(
                        `/view_patient_form/${patientRecord.patient_data[0].user_id}`
                      )
                    }
                  >
                    Self Assement Data
                  </button>
                </div>
                <div className="m-0 mt-2 p-0">
                  <button
                    className="btn btn-purple w-100"
                    onClick={() =>
                      Navigate(
                        `/sleepdata/${patientRecord.patient_data[0].user_id}`
                      )
                    }
                  >
                    Sleep Data
                  </button>
                </div>
              </>
            );
          })}
        </div>
        <div className="card px-3 py-2">
          <h6 className="fw-600">Conversations/Coaching</h6>
          <div className="Conversations_scrollbar" id="style-3">
            <div className="Conversations_force_overflow">
              {patientRecord?.appointment_notes?.length > 0 ? (
                patientRecord?.appointment_notes?.map(
                  (note: any, index: string) => {
                    return (
                      <p key={index} className="my-0 mt-2">
                        <span className="border_primary text-purple fw-600 me-2 p-1">
                          {note?.date}
                        </span>
                        <span>{note?.notes}</span>
                      </p>
                    );
                  }
                )
              ) : (
                <p className="m-0 fs-14">No Conversations/Coaching Yet</p>
              )}
            </div>
          </div>
        </div>
        <div className="mt-2">
          {/* <div className="text-center my-2">
                  <span className="text-primary fw-bold text-decoration-underline cursor-pointer">
                    Start Video Conference
                  </span>
                </div> */}
          <form onSubmit={submitHandler}>
            <textarea
              className="w-100 border border-purple rounded-2 my-md-2"
              placeholder="No Medication Yet"
              value={medication}
              onChange={medicationChangeHandler}
              rows={8}
            ></textarea>
            <textarea
              className="w-100 border border-purple rounded-2"
              placeholder="No Notes Yet"
              value={doctorNotes}
              onChange={notesChangeHandler}
              rows={8}
            ></textarea>
            {/* <div className="text-center my-3">
              <button
                type="submit"
                className="btn btn-purple text-white px-5 py-2 border border-0"
              >
                Submit
              </button>
            </div> */}
            <div className="text-center my-3">
              {isCurrentAppointment && (
                <button
                  className="btn btn-purple text-white px-5 py-2 border border-0"
                  onClick={handleConfirmModal}
                >
                  {/* {isShowLoading ? (
                            <>
                              <Spin
                                animation="border"
                                variant="purple"
                                size="sm"
                                className="me-3"
                              />{" "}
                              Submitting...
                            </>
                          ) : ( */}
                  Submit
                  {/* )} */}
                </button>
              )}
            </div>
          </form>
        </div>
      </Container>
    </>
  );
};

export default PatientData;
