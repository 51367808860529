import { useContext } from "react";
import Registration from "./pages/Registration";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import DashboardLayout from "./dashboard/DashboardLayout";
import PatientDashboard from "./pages/patient/PatientDashboard";
import AppointmentsPage from "./pages/patient/AppointmentsPage";
import CreateAppointmentPage from "./pages/patient/CreateAppointmentPage";
import AuthContext from "./context/authContext1";
import DoctorList from "./pages/admin/DoctorList";
import DoctorDashboard from "./pages/doctor/DoctorDashboard";
import PatientList from "./pages/admin/PatientList";
import NotificationCategory from "./pages/admin/NotificationCategory";
import Step from "./pages/patient/questionnaire/Step";
import SleepData from "./pages/doctor/SleepData";
import ResourcePage from "./pages/patient/ResourcePage";
import DoctorSchedule from "./pages/doctor/DoctorSchedule";
import DoctorAppointments from "./pages/doctor/DoctorAppointments";
import PatientData from "./pages/doctor/PatientData";
import PatientSleepProfile from "./pages/patient/PatientSleepProfile";
import PatientForm from "./pages/patient/PatientForm";
import NotificationPage from "./pages/doctor/NotificationPage";
import ViewPatientData from "./pages/doctor/ViewPatientData";
import ViewPatientForm from "./pages/doctor/ViewPatientForm";
import LandingPage from "./pages/LandingPage";
import ViewData from "./pages/patient/ViewData";
import PatientSubscriptionFlow from "./pages/patient/PatientSubscriptionFlow";
import LandingLayout from "./dashboard/LandingLayout";
import BlogsPage from "./components/landing/BlogsPage";
import WeightLossPage from "./pages/WeightLossPage";
import WorkPage from "./pages/WorkPage";
import HealthPage from "./pages/HealthPage";
import OurSolutionPage from "./pages/OurSolutionPage";
import WhoopData from "./pages/WhoopData";
import StripeSuccess from "./pages/StripeSuccess";
import StripeFail from "./pages/StripeFail";
import ErrorPage from "./pages/ErrorPage";
import UseNetwork from "./utils/useNetwork";
import OfflinePage from "./pages/OfflinePage";
import DeleteAccount from "./pages/DeleteAccount";
import ResetPassword from "./pages/ResetPassword";
import SleepPatternWearable from "./components/doctor/SleepPatternWearable";

const App = () => {
  const { isAuthenticated, userRole, isFirsttimeLogIn } =
    useContext(AuthContext);
  const { online } = UseNetwork();

  let routes;

  if (userRole === "superadmin") {
    routes = (
      <>
        <Route path="/managedoctors" element={<DoctorList />} />
        <Route path="/admin_patient_lists" element={<PatientList />} />
        <Route
          path="/admin_notifications_category"
          element={<NotificationCategory />}
        />
      </>
    );
  } else if (userRole === "patient") {
    if (isFirsttimeLogIn) {
      routes = (
        <Route
          path="/patientsubscription"
          element={<PatientSubscriptionFlow />}
        />
      );
    } else {
      routes = (
        <>
          <Route index path="/dashboard" element={<PatientDashboard />} />
          <Route path="/patient_appointments" element={<AppointmentsPage />} />
          <Route path="/sleepdata/:patientId" element={<SleepData />} />
          {/* <Route
            path="/patientsubscription"
            element={<PatientSubscriptionFlow />}
          /> */}
          <Route
            path="/patient_appointments/create"
            element={<CreateAppointmentPage />}
          />
          <Route path="/questionarie" element={<Step />} />
          <Route path="/resource" element={<ResourcePage />} />
          <Route path="/sleepprofile" element={<PatientSleepProfile />} />
          <Route path="/viewpatientform/:userId" element={<PatientForm />} />
          <Route path="/success/:code" element={<ViewData />} />
        </>
      );
    }
  } else if (userRole === "doctor") {
    routes = (
      <>
        <Route index path="/doctorsPatientList" element={<DoctorDashboard />} />
        <Route path="/sleepdata/:patientId" element={<SleepData />} />
        <Route path="/doctorschedule" element={<DoctorSchedule />} />
        <Route path="/doctorappointments" element={<DoctorAppointments />} />
        <Route path="/doctorappointment/:patientId" element={<PatientData />} />
        <Route
          path="/doctorsPatientList/:patientId"
          element={<PatientData />}
        />
        <Route path="/notification/:patientId" element={<NotificationPage />} />
        <Route path="/viewdata/:patientId" element={<ViewPatientData />} />
        <Route
          path="/sleeppatternwearable/:patientId"
          element={<SleepPatternWearable />}
        />
        <Route
          path="/view_patient_form/:patientId"
          element={<ViewPatientForm />}
        />
      </>
    );
  }

  let LandingPageroutes = (
    <>
      <Route path="/weight_loss" element={<WeightLossPage />} />
      <Route path="/work" element={<WorkPage />} />
      <Route path="/health" element={<OurSolutionPage />} />
      <Route path="/our_solution" element={<HealthPage />} />
      <Route path="/blogs" element={<BlogsPage />} />
    </>
  );
  return (
    <>
      {online ? (
        <>
          <Routes>
            <Route path="/reset-password/:id" element={<ResetPassword />} />
            <Route path="/datadeletion" element={<DeleteAccount />} />
            <Route path="/success" element={<StripeSuccess />} />
            <Route path="/cancel" element={<StripeFail />} />
            <Route path="/viewdata" element={<ViewData />} />
            <Route path="/auth/whoop/callback" element={<WhoopData />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<Registration />} />
            {isAuthenticated ? (
              <Route element={<DashboardLayout />}>{routes}</Route>
            ) : (
              <Route path="/login" element={<LoginPage />} />
            )}
            <Route element={<LandingLayout />}>{LandingPageroutes}</Route>
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </>
      ) : (
        <OfflinePage />
      )}
    </>
  );
};

export default App;
